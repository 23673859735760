import React from 'react';

import { Button, Input, Radio, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

export function getColumnSearchPropsV2(
  dataIndex,
  searchText,
  searchedColumn,
  setSearchText,
  setSearchedColumn,
  searchInput,
  setHasMore,
  module,
  setSorting,
  setSortColumn,
  setSorterInfo
) {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e?.target?.value ? [e?.target?.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(
              selectedKeys,
              confirm,
              dataIndex,
              setSearchText,
              setSearchedColumn
            )
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                setSearchText,
                setSearchedColumn
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(
                clearFilters,
                setSearchText,
                setHasMore,
                setSearchedColumn,
                setSorting,
                setSortColumn,
                setSorterInfo
              )
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    render: (text) => {
      {
        return searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ''}
          />
        ) : (
          text || '-'
        );
      }
    }
  };
}

function handleSearch(
  selectedKeys,
  confirm,
  dataIndex,
  setSearchText,
  setSearchedColumn
) {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
}

function handleReset(
  clearFilters,
  setSearchText,
  setHasMore,
  setSearchedColumn,
  setSorting,
  setSortColumn,
  setSorterInfo
) {
  clearFilters();
  setSearchText();
  if (setHasMore) {
    setHasMore(true);
  }
  if (setSorting && setSortColumn) {
    setSorting('desc');
    setSortColumn('createdAt');
    setSorterInfo();
  }
  setSearchedColumn();
}
