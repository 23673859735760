import { Button, Col, Descriptions, Modal, Row, Typography } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import React, { useContext, useState } from 'react';
import api from '../../../../common/api';
import { AppContext } from '../../../../AppContext';
import Loader from '../../../Loader/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';

function BatchJigSummary({
  type = 'Batch',
  buttonText = 'Print Batch',
  cardColor,
  cardType
}) {
  const [isSummeryModalOpen, setIsSummeryModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [imagePreview, setImagePreview] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const {
    dispatch,
    state: { selectedCardsToPrint }
  } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const material = queryParams.get('material');

  const handleOk = () => {
    if (current === 3) {
      setIsSummeryModalOpen(false);
      dispatch({ type: 'SET_SELECTED_CARDS_TO_PRINT', data: [] });
    }
    if (current === 2) {
      setCurrent(3);
      handleCreateJigs();
    }
    if (current === 1) {
      setCurrent(2);
      getCardsPreview();
    }
  };

  const showModal = () => {
    setIsSummeryModalOpen(true);
  };
  const handleCancel = () => {
    setCurrent(1);
    setIsSummeryModalOpen(false);
  };
  const handleCreateJigs = async () => {
    try {
      const apiUrl = `${
        process.env.REACT_APP_API_GTW_URL
      }/v1/print-ready/jigs/${material === 'metal' ? 'v2' : 'v1'}`;
      const bodyContent =
        material === 'metal'
          ? {
              algoliaDesignSetIds: selectedCardsToPrint,
              cardType: cardType,
              cardColor: cardColor[0]
            }
          : {
              cardIds: selectedCardsToPrint,
              cardType: cardType,
              cardColor: cardColor[0]
            };

      await api.post(apiUrl, bodyContent);
    } catch (error) {
      console.error(error);
    }
  };

  const getCardsPreview = async () => {
    try {
      setPreviewLoading(true);
      const params = selectedCardsToPrint.map((code) =>
        material === 'metal'
          ? `algoliaDesignSetIds[]=${encodeURIComponent(code)}`
          : `cardCodes[]=${encodeURIComponent(code)}`
      );
      const queryString = params.join('&');
      const apiUrl =
        material === 'metal'
          ? `${process.env.REACT_APP_API_GTW_URL}/v1/print-ready/designs?${queryString}&pageNumber=1&pageSize=100`
          : `${process?.env?.REACT_APP_API_GTW_URL}/v1/cards/preview?${queryString}`;
      const cardPreviewResponse = await api?.get(apiUrl);
      setImagePreview(
        material === 'wood'
          ? cardPreviewResponse?.data?.data
          : cardPreviewResponse?.data?.data?.results
      );
      setPreviewLoading(false);
    } catch (error) {
      setPreviewLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          showModal();
        }}
      >
        {buttonText}
      </Button>
      <Modal
        visible={isSummeryModalOpen}
        onCancel={handleCancel}
        width={1298}
        onOk={handleOk}
      >
        {current === 1 && (
          <div>
            <Typography.Text strong>{type} Summary</Typography.Text>
            <Descriptions title="Current filter">
              <DescriptionsItem label="Card Type">
                {cardType ?? '-'}
              </DescriptionsItem>
              <DescriptionsItem label="Card Color">
                {cardColor ?? '-'}
              </DescriptionsItem>
            </Descriptions>
            <Descriptions title="Cards details">
              <DescriptionsItem label="Number of Cards">
                {selectedCardsToPrint?.length}
              </DescriptionsItem>
            </Descriptions>
          </div>
        )}
        {current === 2 && (
          <div>
            <Typography.Text strong>{type} Preview</Typography.Text>
            {previewLoading ? (
              <Loader />
            ) : (
              <Row className="mt-16" gutter={8}>
                {imagePreview?.map((image) => {
                  return (
                    <Col key={image?.cardCode} span={6}>
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <img
                              src={image?.frontBGPngUrl || image?.frontPngUrl}
                              alt="card preview"
                              className="card-image"
                            />
                          </div>
                          {image?.backPngUrl && (
                            <div className="flip-card-back">
                              <img
                                src={image?.backBGPngUrl || image?.backPngUrl}
                                alt="card preview"
                                className="card-image"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="card-close-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setImagePreview(
                            imagePreview.filter(
                              (item) => item?.objectID !== image?.objectID
                            )
                          );
                          const filteredData = selectedCardsToPrint.filter(
                            (item) => item !== image?.objectID
                          );
                          dispatch({
                            type: 'SET_SELECTED_CARDS_TO_PRINT',
                            data: filteredData
                          });
                        }}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        )}
        {current === 3 && (
          <div>
            <Typography.Text strong>
              {type} creation process has been started &nbsp;
              <Typography.Link
                onClick={() => {
                  history.push({
                    pathname: '/jigs',
                    search: `?material=${material}`
                  });
                }}
              >
                Click
              </Typography.Link>
              here to see the status
            </Typography.Text>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default BatchJigSummary;
