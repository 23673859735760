import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import {
  LeftOutlined,
  DeleteOutlined,
  SyncOutlined,
  UpCircleOutlined
} from '@ant-design/icons';

import AddDepartment from './AddDepartment';
import { firebase } from '../../../firebase';
import Highlighter from 'react-highlight-words';
import confirm from 'antd/lib/modal/confirm';
import { LIMIT_PRIMARY } from '../../../common/constant';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import { scrollToTop } from '../../../common/utils';

function Departments() {
  const { id } = useParams();
  const history = useHistory();
  const [org, setOrg] = useState({});
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [departments, setDepartments] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);

  const orgRef = firebase.firestore().collection('organizations').doc(id);
  const profileRef = firebase.firestore().collection('profiles');
  let departmentRef = orgRef.collection('departments');
  if (searchText && searchedColumn) {
    if (searchedColumn === 'name') {
      departmentRef = departmentRef
        .orderBy(searchedColumn)
        .startAt(searchText?.toUpperCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  }
  departmentRef = departmentRef.limit(LIMIT_PRIMARY);

  const getOrg = () => {
    orgRef.get().then((res) => {
      setOrg(res?.data());
    });
  };
  const getDepartments = () => {
    departmentRef.get().then((res) => {
      const items = [];
      res.docs.forEach((doc) => {
        items.push(doc?.data());
      });
      setOffset(res?.docs[res?.docs?.length - 1]);
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setDepartments(items);
    });
  };
  const getNextDepartments = () => {
    departmentRef
      .startAfter(offset)
      .get()
      .then((res) => {
        const items = [];
        res.docs.forEach((doc) => {
          items.push(doc?.data());
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(res?.docs[res?.docs?.length - 1]);
        setDepartments([...departments, ...items]);
      });
  };
  useEffect(() => {
    getOrg();
    getDepartments();
  }, [searchText]);

  useEffect(() => {
    setLoading(true);
    profileRef
      .where('orgId', '==', id)
      .get()
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc?.data(), docId: doc?.id });
        });
        setProfiles(items);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchPropsV2(
        'name',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      ),
      render: (text, record) => {
        return (
          <Link to={`/v2-organizations/${id}/groups/${record?.id}`}>
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text?.toString() : ''}
            />
          </Link>
        );
      }
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      render: (text, record) => {
        return <Typography.Text>{text?.length}</Typography.Text>;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      render: function Delete(text, record) {
        function showConfirm() {
          confirm({
            title: `Are you sure you want to delete group '${record?.name}'`,
            onOk() {
              orgRef
                .collection('departments')
                .doc(record?.id)
                .collection('config')
                .get()
                .then((querySnapshot) => {
                  const batch = firebase.firestore().batch();
                  querySnapshot.forEach(function (doc) {
                    batch.delete(doc?.ref);
                  });
                  firebase
                    .firestore()
                    .collection('organizations')
                    .doc(id)
                    .collection('departments')
                    .doc(record?.id)
                    .delete()
                    .then(() => {
                      message.success('Group deleted successfully');
                    });
                  return batch.commit();
                });
            },
            onCancel() {}
          });
        }
        const disabled = record?.members?.length != 0;
        return (
          <Space>
            <Button
              disabled={disabled}
              type="text"
              icon={
                <DeleteOutlined
                  style={{ color: 'red', opacity: disabled ? 0.4 : 1 }}
                />
              }
              onClick={showConfirm}
            />
            <Button
              type="text"
              icon={<SyncOutlined />}
              onClick={() => {
                const syncedMembers = [];
                profiles.forEach((profile) => {
                  if (profile?.deptId === record?.id) {
                    syncedMembers.push(profile?.docId);
                  }
                });
                orgRef
                  .collection('departments')
                  .doc(record?.id)
                  .update({ members: syncedMembers })
                  .then(() => {
                    message.success('Members synced successfully');
                  });
              }}
            />
          </Space>
        );
      }
    }
  ];

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.scrollY > 300);
    });
  }, []);
  return (
    <Card
      title={
        <Space>
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => {
              history.goBack();
            }}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            Groups of {org?.name}
          </Typography.Title>
        </Space>
      }
      extra={
        <Space>
          <AddDepartment orgId={id} />
        </Space>
      }
    >
      <Table
        dataSource={departments}
        columns={columns}
        pagination={false}
        bordered={true}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextDepartments();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default Departments;
