import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { QrcodeOutlined, UpCircleOutlined } from '@ant-design/icons';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';

import { firebase } from '../../../firebase';
import './card.css';
import GetUserNameById from '../../../utils/getUserNameById';
import ReprintCard from './Reprint';
import ReplaceCard from './Replace';
import ReleaseReassign from './ReleaseReassign';
import { catchErrorInSentry, scrollToTop } from '../../../common/utils';

function Cards() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState();
  const [searchText, setSearchText] = useState('');
  const [cardsOffset, setCardsOffset] = useState();
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [profileIds, setProfileIds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const searchInput = useRef(null);

  let cardsRef = firebase.firestore().collection('cards');
  let profileRef = firebase.firestore().collection('profiles');

  if (searchText && searchedColumn) {
    if (searchedColumn === 'cardCode') {
      cardsRef = cardsRef
        .orderBy(searchedColumn)
        .startAt(searchText?.trim())
        .endAt(searchText?.trim());
    } else if (
      searchedColumn === 'orderUniqueId' ||
      searchedColumn === 'orderNumber'
    ) {
      cardsRef = cardsRef
        .orderBy(searchedColumn)
        .startAt(searchText?.toUpperCase())
        .endAt(searchText?.toUpperCase() + '\uf8ff');
    } else if (
      (searchedColumn === 'owner' || searchedColumn === 'showProfile') &&
      profileIds < 1
    ) {
      profileRef = profileRef
        .orderBy('user')
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    } else if (profileIds?.length > 0) {
      if (searchedColumn === 'owner') {
        cardsRef = cardsRef.where('owner', 'in', profileIds);
      } else if (searchedColumn === 'showProfile') {
        cardsRef = cardsRef.where('showProfile', 'in', profileIds);
      }
    }
  }
  profileRef = profileRef.limit(10);
  cardsRef = cardsRef.limit(10);
  const columns = [
    {
      title: 'Card code',
      dataIndex: 'cardCode',
      key: 'cardCode',
      ...getColumnSearchPropsV2(
        'cardCode',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      )
    },
    {
      title: 'Order unique ID',
      dataIndex: 'orderUniqueId',
      key: 'orderUniqueId',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      )
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      )
    },
    {
      title: 'Owner Email ID',
      dataIndex: 'owner',
      key: 'owner',
      ...getColumnSearchPropsV2(
        'owner',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore,
        setProfileIds,
        profileIds
      ),
      render: (text) => {
        return <GetUserNameById userId={text} />;
      }
    },
    {
      title: 'Show Profile Email Id',
      dataIndex: 'showProfile',
      key: 'showProfile',
      ...getColumnSearchPropsV2(
        'showProfile',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore,
        setProfileIds,
        profileIds
      ),
      render: (text) => {
        return <GetUserNameById userId={text} />;
      }
    },
    {
      title: 'Reprint count',
      dataIndex: 'reprintCount',
      key: 'reprintCount',
      render: (text) => text || '-'
    },
    {
      title: 'Original Card',
      dataIndex: 'originalCardCode',
      key: 'originalCardCode',
      render: (text) => text || '-'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: function Reprocess(text, record) {
        const [disabled, setDisabled] = useState(false);
        return (
          <Space size={0}>
            <Switch
              size="small"
              checked={!record?.disabled}
              disabled={
                record?.activeLink?.includes('/cards/expired') ? true : false
              }
              onChange={(e) => {
                firebase
                  .firestore()
                  .collection('cards')
                  .doc(record?.cardCode)
                  .update({
                    activeLink: !e
                      ? `${process?.env?.REACT_APP_SERVER_URL}/cards/blocked`
                      : record?.disabledActiveLink || '',
                    disabledActiveLink: record?.activeLink || '',
                    disabled: !e
                  })
                  .then(() => {
                    const successMessage = e ? 'Card Enabled' : 'Card Disabled';
                    getCards();
                    message.success(successMessage);
                  })
                  .catch((e) => {
                    catchErrorInSentry(e);
                    message.error('Some error occured');
                  });
              }}
            />
            <ReprintCard
              record={record}
              disabled={disabled}
              setDisabled={setDisabled}
              onFinish={getCards}
            />
            <ReplaceCard
              record={record}
              disabled={disabled}
              setDisabled={setDisabled}
              onFinish={getCards}
            />
            <Tooltip title="Click to scan">
              <Button
                target="_blank"
                href={record?.qrcode}
                disabled={!record?.qrcode}
                type="text"
                icon={<QrcodeOutlined />}
              />
            </Tooltip>
            <ReleaseReassign record={record} />
          </Space>
        );
      }
    }
  ];
  async function getCards() {
    setLoading(true);
    const cardsData = await cardsRef.get();
    const items = [];
    cardsData.forEach((card) => {
      items.push(card?.data());
    });
    if (items?.length < 10) {
      setHasMore(false);
    }
    setCardsOffset(cardsData?.docs[cardsData?.docs?.length - 1]);
    setCards(items);
    setLoading(false);
  }
  const getNextCards = async () => {
    const cardsData = await cardsRef.startAfter(cardsOffset).get();
    const items = [];
    cardsData.forEach((card) => {
      items.push(card?.data());
    });
    if (items?.length < 10) {
      setHasMore(false);
    }
    setCardsOffset(cardsData?.docs[cardsData?.docs?.length - 1]);
    setCards([...cards, ...items]);
  };
  const getProfileData = async () => {
    const profileData = await profileRef.get();
    let ids = [];
    profileData.docs.forEach((doc) => {
      ids.push(doc?.data()?.userId);
    });
    setProfileIds(ids);
  };
  useEffect(() => {
    getCards();
    if (searchedColumn === 'owner' || searchedColumn === 'showProfile') {
      getProfileData();
    }
  }, [searchText]);
  useEffect(() => {
    if (profileIds?.length > 0) {
      getCards();
    }
  }, [profileIds]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.scrollY > 300);
    });
  }, []);
  return (
    <Card
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Cards
        </Typography.Title>
      }
      className="container"
    >
      <Table
        dataSource={cards}
        columns={columns}
        bordered={true}
        pagination={false}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="load-button"
            onClick={() => {
              if (hasMore) {
                getNextCards();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="load-button scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default Cards;
