import React, { useState, useCallback, useEffect } from 'react';
import { useParams, Link, withRouter } from 'react-router-dom';
import { Typography, Button, Collapse, message, Card, Space } from 'antd';
import { firebase } from '../../../../firebase';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Loader from '../../../Loader/Loader';
import CardCodeInput from './CardCodeInput';
import pull from 'lodash/pull';
import { catchErrorInSentry } from '../../../../common/utils';
import api from '../../../../common/api';
const { Panel } = Collapse;
let assignedCode = 0;
let emailIds = [];
let uniqueIds = [];
function AssignCard() {
  let { id } = useParams();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [cardQuantity, setCardQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();

  const incrementAssignedCardQuantity = useCallback(() => {
    assignedCode = assignedCode + 1;
  }, [assignedCode]);

  const getSet = async () => {
    setLoading(true);
    const response = await api?.get(
      `${process.env.REACT_APP_API_GTW_URL}/v1/best-factory/orders/${id}`
    );
    const items = [];
    const temp = [];
    setOrder(response?.data?.data);
    response.data.data.set.cardUsers.forEach((user) => {
      items.push({
        ...user,
        setUniqueId: response?.data?.data?.set?.setUniqueId,
        cardColor:
          response?.data?.data?.set?.cardType === 'Mobilo Metal (promo)' ||
          response?.data?.data?.set?.cardType === 'Mobilo Metal'
            ? user?.cardColor || response?.data?.data?.set?.cardColor
            : undefined
      });
      for (var i = 0; i < user?.quantity; i++) {
        temp.push(user?.email?.toLowerCase());
      }
    });
    setUsers(items);
    setCardQuantity(temp.length);
    setLoading(false);
  };

  useEffect(() => {
    getSet();
    return () => {
      assignedCode = 0;
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={
        <Space>
          <Link to="/print/inprogress">
            <Button type="text" icon={<LeftOutlined />} />
          </Link>
          <Typography.Title style={{ margin: 0 }} level={3}>
            {id}
          </Typography.Title>
        </Space>
      }
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (cardQuantity === assignedCode) {
              firebase
                .firestore()
                .collection('orders')
                .doc(order?.id)
                .set(
                  {
                    allCardsAssigned: true
                  },
                  { merge: true }
                )
                .then(() => {
                  message.success('Card codes assigned to all cards');
                  history.goBack();
                })
                .catch((err) => {
                  catchErrorInSentry(err);
                  console.log(err);
                });
            } else message.error('Please assign card codes to all cards');
          }}
        >
          Done
        </Button>
      }
    >
      <Collapse
        onChange={(e) => {
          emailIds.push(users?.[e.length - 1]?.email);
          pull(uniqueIds, users?.[e.length - 1]?.email);
          if (!uniqueIds?.includes(users?.[e.length - 1]?.email)) {
            uniqueIds.push(users?.[e.length - 1]?.email);
          }
        }}
      >
        {users.map((value, index) => {
          return (
            <Panel
              key={index}
              header={`${value.firstName} ${value.lastName}`}
              extra={
                value.cardColor ? (
                  <Space>
                    <Typography.Title style={{ marginBottom: 0 }} level={5}>
                      Card color:
                    </Typography.Title>
                    <Typography.Text style={{ marginBottom: 0 }}>
                      {value.cardColor}
                    </Typography.Text>
                  </Space>
                ) : null
              }
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                {Array.apply(null, Array(value.quantity)).map((_, index) => (
                  <CardCodeInput
                    record={value}
                    incrementAssignedCardQuantity={
                      incrementAssignedCardQuantity
                    }
                    key={index}
                    itemNumber={index}
                    emailIds={emailIds}
                    uniqueIds={uniqueIds}
                  />
                ))}
              </Space>
            </Panel>
          );
        })}
      </Collapse>
    </Card>
  );
}

export default withRouter(AssignCard);
