import { Button, Modal, Tabs, Tag } from 'antd';
import React from 'react';
import { useState } from 'react';
import { LICENSE_TYPES } from '../../../common/constant';
import CommonTabPan from './CommonTabPan';

const { TabPane } = Tabs;

function ReleaseLicense({ orgId }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const columns = [
    {
      title: 'License Id',
      dataIndex: 'licenseId'
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      render: (data) => {
        return (
          <>
            {data ? (
              <Tag color="green">{data}</Tag>
            ) : (
              <Tag color="red">Not Assigned</Tag>
            )}
          </>
        );
      }
    },
    {
      title: 'Email Id',
      dataIndex: 'email'
    }
  ];

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Release License
      </Button>
      <Modal
        title="Release License"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        width={760}
      >
        <Tabs defaultActiveKey="1" size="large" type="card">
          {Object.entries(LICENSE_TYPES)?.map(([key, value]) => {
            return (
              <TabPane tab={value} key={key}>
                <CommonTabPan
                  licenseType={key}
                  orgId={orgId}
                  columns={columns}
                  isRelease={true}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    </>
  );
}

export default ReleaseLicense;
