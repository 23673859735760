import {
  Button,
  Card,
  message,
  Result,
  Row,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router';
import { LeftOutlined, QrcodeOutlined } from '@ant-design/icons';
import { firebase } from '../../../firebase';
import { Link } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import axios from 'axios';
import { catchErrorInSentry } from '../../../common/utils';
import api from '../../../common/api';

function Cards() {
  const { id } = useParams();
  const history = useHistory();
  const [cards, setCards] = useState([]);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const cardTypes = [
    'Mobilo Card',
    'Mobilo Card (custom)',
    'Enterprise Service',
    'Mobilo Metal (promo)',
    'Mobilo Metal',
    'Mobilo Wood',
    'Mobilo Wood (promo)',
    'Free Mobilo Card'
  ];

  const ref = firebase
    .firestore()
    .collection('cards')
    .where('orderUniqueId', '==', id);

  async function getOrder() {
    setLoading(true);
    const response = await api?.get(
      `${process.env.REACT_APP_API_GTW_URL}/v1/best-factory/orders/${id}`
    );
    setOrder({
      ...response?.data?.data,
      orderNumber: response?.data?.data?.shippingAddress.order_number
        ? `${response?.data?.data?.orderNumber}-(${response?.data?.data?.shippingAddress?.order_number})`
        : `${response?.data?.data?.orderNumber}`
    });
    setLoading(false);
  }

  function getCards() {
    setLoading(true);

    const items = [];
    ref.get().then((cards) => {
      cards.forEach((card) => {
        items.push(card?.data());
      });
      setCards(items);
      setLoading(false);
    });
  }
  const columns = [
    {
      title: 'Card code',
      dataIndex: 'cardCode',
      key: 'cardCode'
    },
    {
      title: 'QR code',
      render: (text, record) => {
        return (
          <Tooltip title="Click to scan">
            <a target="_blank" href={record?.qrcode}>
              <Button type="text" icon={<QrcodeOutlined />} />
            </a>
          </Tooltip>
        );
      }
    }
  ];

  useEffect(() => {
    getCards();
    getOrder();
  }, []);

  const handleDownLoadCsv = async () => {
    setDownloading(true);
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_SERVER_URL}/v2/mat/printer/export-csv?orderUniqueId=${id}&orderNumber=${order?.orderNumber}`,
          headers: { authorization: `Bearer ${token}` }
        };
        axios(config)
          .then((res) => {
            message.success(res?.data?.message);
            window.location.href = res?.data?.data;
            setDownloading(false);
          })
          .catch((error) => {
            message.error(error?.response?.data?.message);
            setDownloading(false);
          });
      })
      .catch((error) => {
        catchErrorInSentry(error);
        message.error('Something went wrong');
      });
  };
  function Layout({ children }) {
    return (
      <Card
        title={
          <Space>
            <Button
              onClick={() => {
                history.goBack();
              }}
              type="text"
              icon={<LeftOutlined />}
            />
            <Typography.Title style={{ margin: 0 }} level={3}>
              {id} | Cards
            </Typography.Title>
          </Space>
        }
      >
        {children}
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (
    order?.cardType === cardTypes[0] ||
    order?.cardType === cardTypes[3] ||
    order?.cardType === cardTypes[4] ||
    order?.cardType === cardTypes[7]
  ) {
    const flag = order?.allCardsAssigned || false;
    const message = flag
      ? 'All cards were assigned by you, you can mark this order as completed after printing is done'
      : 'It seems you need to assign all cards for this order';

    return (
      <Layout>
        <Result
          status={flag ? 'success' : 'info'}
          title={message}
          extra={
            flag ? (
              <Button
                onClick={() => {
                  history.goBack();
                }}
                type="primary"
              >
                Go Back
              </Button>
            ) : (
              <Link to={`/print/inprogress/${id}`}>
                <Button type="primary">Assign Codes</Button>
              </Link>
            )
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <Row justify="end" align="middle">
        <Button
          type="primary"
          onClick={() => {
            handleDownLoadCsv();
          }}
          loading={downloading}
        >
          Export Label .csv
        </Button>
      </Row>
      <Table dataSource={cards} columns={columns} />
    </Layout>
  );
}

export default withRouter(Cards);
