import { Button, Card, Image, Space, Table, message } from 'antd';
import React, { useState, useEffect, useRef, useContext } from 'react';
import BatchJigSummary from './BatchJigSummary';
import { useHistory, useLocation } from 'react-router-dom';
import { getColumnSearchPropsV2 } from '../../../../utils/searchV2';
import { AppContext } from '../../../../AppContext';
import { AuthContext } from '../../../../Auth';
import Axios from 'axios';
import { firebase } from '../../../../firebase';
import { filter, uniq } from 'lodash';

const paginationProp = {
  currentPage: 1,
  pageSize: 10
};
function CardList() {
  const { data } = useContext(AuthContext);
  const [cards, setCards] = useState([]);
  const [cardColor, setCardColor] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState(paginationProp);
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState([
    { text: 'Black', value: 'Black' },
    { text: 'Silver', value: 'Silver' },
    { text: 'Gold', value: 'Gold' },
    { text: 'Birch (light)', value: 'Birch (light)' },
    { text: 'Sapele (dark)', value: 'Sapele (dark)' }
  ]);
  const [orders, setOrders] = useState();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const material = queryParams.get('material');

  const searchInput = useRef(null);
  const {
    dispatch,
    state: { selectedCardsToPrint }
  } = useContext(AppContext);

  const columns = [
    {
      title: 'Card Material',
      dataIndex: 'cardType'
    },
    {
      title: 'Card Color',
      dataIndex: 'cardColor',
      filters: filterOptions,
      filterMultiple: false
    },
    {
      title: 'Card Code',
      dataIndex: 'cardCode',
      hidden: material === 'metal'
    },
    {
      title: 'Card thumbnail image',
      dataIndex: 'frontPngUrl',
      render: function Thumbnail(text, record) {
        return (
          <Image
            src={record?.frontBGPngUrl || record?.frontPngUrl}
            width={49}
          />
        );
      }
    },
    {
      title: 'Order uniqueId',
      dataIndex: 'orderUniqueId',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput
      )
    }
  ].filter((item) => !item.hidden);

  const getCardApi = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setLoading(true);
      const response = await Axios?.get(
        `${process?.env?.REACT_APP_API_GTW_URL}/v1/cards/vendor/printer/${
          data?.vendorId
        }/?${cardColor ? `cardColor=${cardColor}` : ''}${
          material === 'wood'
            ? `&cardType[]=Mobilo Wood`
            : `&cardType[]=Mobilo Metal`
        }${searchedColumn ? `&orderUniqueIds[]=${searchText}` : ''}&pageSize=${
          pagination?.pageSize
        }&pageNumber=${pagination?.currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response) {
        setCards(response?.data?.data?.results);
        setPagination({ ...pagination, ...response?.data?.data?.pageInfo });
      }
      setLoading(false);
    } catch (error) {
      setCards([]);
      message.error(error?.response?.data?.reason || 'Cards not found');
      setLoading(false);
      console.log(error);
    }
  };

  const getCardsByOrderUniqueId = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setLoading(true);
      const response = await Axios?.get(
        `${process?.env?.REACT_APP_API_GTW_URL}/v1/cards/search/orders?${
          searchedColumn ? `orderUniqueIds[]=${searchText}` : ''
        }&pageNumber=${pagination?.currentPage}&pageSize=${
          pagination?.pageSize
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.data) {
        setCards(response?.data?.data?.results);
        setPagination({ ...pagination, ...response?.data?.data?.pageInfo });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCards([]);
      message.error(error?.response?.data?.reason || 'Cards not found');
    }
  };
  const getOrdersByVendors = async () => {
    try {
      setLoading(true);
      const token = await firebase.auth().currentUser.getIdToken(true);
      const items = [];
      if (!searchText) {
        const response = await Axios.get(
          `${process.env.REACT_APP_API_GTW_URL}/v1/best-factory/orders/PRINTER/${data?.vendorId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.data.data) {
          response.data.data.map((order) => {
            items.push(order?.orderUniqueId);
          });
        }
        setOrders(items);
      } else {
        items.push(searchText);
      }
      const params = items.map(
        (code) => `orderUniqueIds[]=${encodeURIComponent(code)}`
      );
      const queryString = params.join('&');
      const cardData = await Axios.get(
        `${
          process.env.REACT_APP_API_GTW_URL
        }/v1/print-ready/designs?${queryString}&pageNumber=${
          pagination?.currentPage
        }&pageSize=${pagination?.pageSize}${
          cardColor ? `&cardColor=${cardColor}` : ''
        }&cardType[]=Mobilo Metal`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (cardData) {
        setCards(cardData?.data?.data?.results);
        setPagination({ ...pagination, ...cardData?.data?.data?.pageInfo });
      }
    } catch (error) {
      setCards([]);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (material === 'wood') {
      if (data?.vendorId && !searchText) {
        getCardApi();
      } else if (searchText) {
        getCardsByOrderUniqueId();
      }
    } else {
      getOrdersByVendors();
    }
  }, [
    data,
    cardColor,
    searchText,
    pagination.currentPage,
    pagination.pageSize
  ]);

  useEffect(() => {
    dispatch({
      type: 'SET_SELECTED_CARDS_TO_PRINT',
      data: []
    });
  }, []);

  useEffect(() => {
    if (material === 'wood') {
      setFilterOptions([
        { text: 'Birch (light)', value: 'Birch (light)' },
        { text: 'Sapele (dark)', value: 'Sapele (dark)' }
      ]);
    } else if (material === 'metal' && data?.vendorId) {
      setFilterOptions([
        { text: 'Black', value: 'Black' },
        { text: 'Silver', value: 'Silver' },
        { text: 'Gold', value: 'Gold' }
      ]);
    } else {
      setFilterOptions([
        { text: 'Black', value: 'Black' },
        { text: 'Silver', value: 'Silver' },
        { text: 'Gold', value: 'Gold' },
        { text: 'Birch (light)', value: 'Birch (light)' },
        { text: 'Sapele (dark)', value: 'Sapele (dark)' }
      ]);
    }
  }, [location, cardColor]);

  const rowSelection = {
    selectedRowKeys: selectedCardsToPrint,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected === false) {
        dispatch({
          type: 'SET_SELECTED_CARDS_TO_PRINT',
          data: selectedCardsToPrint.filter((key) => key !== record?.objectID)
        });
        selectedCardsToPrint.filter((key) => key !== record?.objectID);
      } else {
        dispatch({
          type: 'SET_SELECTED_CARDS_TO_PRINT',
          data: [...selectedCardsToPrint, record?.objectID]
        });
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected === true) {
        const items = [];
        changeRows.map((row) => {
          items.push(row?.objectID);
        });
        dispatch({
          type: 'SET_SELECTED_CARDS_TO_PRINT',
          data: [...selectedCardsToPrint, ...items]
        });
      } else {
        const objectIDsArray = changeRows.map((item) => item?.objectID);
        const filteredArray = selectedCardsToPrint.filter(
          (id) => !objectIDsArray.includes(id)
        );
        dispatch({
          type: 'SET_SELECTED_CARDS_TO_PRINT',
          data: filteredArray
        });
      }
    },

    getCheckboxProps: () => {
      return {
        disabled: !cardColor
      };
    }
  };
  const handleTableChange = (pagination, filter) => {
    setPagination({
      ...pagination,
      currentPage: pagination?.current,
      pageSize: pagination?.pageSize
    });

    setCardColor(filter?.cardColor);
  };
  return (
    <Card
      extra={
        <Space direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              history.push({
                pathname: '/jigs',
                search: `?material=${material}`
              });
            }}
          >
            View Jigs
          </Button>
          {selectedCardsToPrint?.length > 0 && (
            <BatchJigSummary
              buttonText="Create Jig"
              type="Jig"
              cardColor={cardColor}
              cardType={material === 'wood' ? 'Mobilo Wood' : 'Mobilo Metal'}
            />
          )}
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={cards}
        rowSelection={rowSelection}
        rowKey={material === 'metal' ? 'objectID' : 'cardCode'}
        onChange={handleTableChange}
        pagination={{
          defaultPageSize: 10,
          responsive: true,
          showSizeChanger: true,
          position: ['bottomRight'],
          total: pagination?.totalPage * pagination?.pageSize
        }}
        loading={loading}
      />
    </Card>
  );
}

export default CardList;
