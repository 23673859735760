import React from 'react';
import { Button, Modal, Form, Input, Select, message, Checkbox } from 'antd';
import { firebase } from '../../../firebase';
import { nanoid } from 'nanoid';
import { ACTION_TYPE } from '../../../common/constant';
import api from '../../../common/api';

const axios = require('axios');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function AddOrganization() {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    const { name } = event;
    setLoading(true);
    try {
      const body = {
        app_id: process?.env?.REACT_APP_FIREBASE_PROJECT_ID
      };
      const response = await api.post(
        `${process?.env?.REACT_APP_API_GTW_URL}/v1/organizations`,
        {
          name,
          admins: [],
          members: []
        }
      );

      const res = await axios.post(
        `${process?.env?.REACT_APP_SERVER_URL}/v2/algolia/token`,
        body
      );
      if (res?.data?.data) {
        axios
          .post(
            `${process?.env?.REACT_APP_SERVER_URL}/v2/algolia/sync`,
            {
              type: process?.env?.REACT_APP_ALGOLIA_INDEX || 'organizations',
              action: ACTION_TYPE?.CREATE,
              id: response?.data?.data?.id
            },
            {
              headers: {
                authorization: `Bearer ${res?.data?.data}`
              }
            }
          )
          .then(() => {
            setLoading(false);
            setVisible(false);
            form.resetFields();
            message.success('Organization added successfully');
          });
      }
    } catch (error) {
      alert(error);
      message.error('Something went wrong!');
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Create Organization
      </Button>
      <Modal
        title="Create Organization"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Create
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddOrganization;
