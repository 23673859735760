import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Form,
  Select,
  message,
  Switch,
  Upload,
  Alert,
  Row,
  Card,
  Input,
  Spin,
  Space,
  Col,
  Descriptions,
  Checkbox
} from 'antd';
import { firebase } from '../../../firebase';
import {
  UploadOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { getCodeSnippet } from '../../../utils/getCodeSnippet';
import {
  BRANDED_LINK_TYPE,
  EMAIL_ONBOARDING_TYPE
} from '../../../common/constant';
import api from '../../../common/api';
import { catchErrorInSentry } from '../../../common/utils';

const { Option } = Select;
const { TextArea } = Input;
const axios = require('axios');

const tailLayout = {
  wrapperCol: { offset: 10, span: 14 }
};

function Settings({ orgId }) {
  const [token, setToken] = useState();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [socialPage, setSocialPage] = useState(false);
  const [leadPage, setLeadPage] = useState(false);
  const [hasSocialPage, setHasSocialPage] = useState(false);
  const [hasEmailSignaturePage, setHasEmailSignaturePage] = useState(false);
  const [hasLeadGen, setHasLeadGen] = useState(false);
  const [socialPageLoading, setSocialPageLoading] = useState(false);
  const [leadGenLoading, setLeadGenLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSignaturePage, setEmailSignaturePage] = useState(false);
  const [allowInstructionAndTagging, setAllowInstructionAndTagging] =
    useState(true);
  const [showViralLink, setShowViralLink] = useState(true);
  const [custom, setCustom] = useState(false);
  const [tagName, setTagName] = useState('');
  const [isNotLegacy, setIsNotLegacy] = useState(false);
  const [linkModes, setLinkModes] = useState(['Not Selected']);
  const [prevLinkMode, setPrevLinkMode] = useState([]);
  const [hasMicrosoftSSO, setHasMicrosoftSSO] = useState(false);

  // for code snippet
  const [isHeaderSnippet, setIsHeaderSnippet] = useState(false);
  const [isBodySnippet, setIsBodySnippet] = useState(false);
  const [headerSnippet, setHeaderSnippet] = useState('');
  const [bodySnippet, setBodySnippet] = useState('');
  const [snippetLoader, setSnippetLoader] = useState(false);

  // for branded link
  const [isBrandedLinkUsed, setIsBrandedLinkUsed] = useState(false);
  const [brandedLinkDomain, setBrandedLinkDomain] = useState('');
  const [isBrandedLinkDomainVerified, setIsBrandedLinkDomainVerified] =
    useState(false);
  const [addingBrandedLink, setAddingBrandedLink] = useState(false);
  const [removingBrandedLink, setRemovingBrandedLink] = useState(false);
  const [verifyingBrandedLink, setVerifyingBrandedLink] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [isPoopupOpen, setIsPoopupOpen] = useState(false);
  const [isHeaderSnippetUpdated, setIsHeaderSnippetUpdated] = useState(false);
  const [isBodySnippetUpdated, setIsBodySnippetUpdated] = useState(false);
  const [emailCommunicationType, setEmailCommunicationType] = useState([]);
  const [tipsAndTricks, setTipsAndTricks] = useState(true);
  const [isEDO, setIsEDO] = useState(false);
  const getToken = async () => {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        setToken(token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpload = (file, type, setLoading) => {
    setLoading(true);
    const dir =
      type === 'social'
        ? 'social'
        : type === 'email'
        ? 'email_signature'
        : 'leadGen';
    const isZip =
      file.type === 'application/x-zip-compressed' ||
      file.type === 'application/zip';

    if (!isZip) {
      message.error('you can only upload zip file');
      setLoading(false);
      return;
    } else {
      var storageRef = firebase?.storage()?.ref();
      const fileName = `${orgId}.zip`;
      var uploadTask = storageRef
        .child(`orgDesign/${dir}/${fileName}`)
        .put(file);
      uploadTask.on(
        'state_changed',
        function (snapshot) {},
        function (error) {},
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            api
              .post(`${process.env.REACT_APP_PDF_DOWNLOAD_URL}/unzip`, {
                filePath: `orgDesign/${dir}/${fileName}`,
                folder: dir,
                orgId: orgId
              })
              .then(function (response) {
                if (type == 'social') {
                  firebase
                    .firestore()
                    .collection('organizations')
                    .doc(orgId)
                    .set({ hasSocialPage: true }, { merge: true })
                    .then(() => {
                      message.success('uploaded successfully');
                      setHasSocialPage(true);
                      setLoading(false);
                    });
                } else if (type == 'email') {
                  firebase
                    .firestore()
                    .collection('organizations')
                    .doc(orgId)
                    .set({ hasEmailSignaturePage: true }, { merge: true })
                    .then(() => {
                      message.success('uploaded successfully');
                      setHasEmailSignaturePage(true);
                      setLoading(false);
                    });
                } else {
                  firebase
                    .firestore()
                    .collection('organizations')
                    .doc(orgId)
                    .set({ hasLeadGen: true }, { merge: true })
                    .then(() => {
                      setHasLeadGen(true);
                      setLoading(false);
                      message.success('uploaded successfully');
                    });
                }
              })
              .catch(function (error) {
                message.error('Some error occurred while uploading..');
                setLoading(false);
              });
          });
        }
      );
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    if (isHeaderSnippetUpdated || isBodySnippetUpdated) {
      uploadCodeSnippet();
    }
    const {
      customSocialPage,
      customLeadPage,
      customEmailSignaturePage,
      legacy,
      microsoftSSO,
      header,
      body,
      customAllowInstructionAndTagging,
      showViralLink,
      EDO
    } = event;
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/org-onboarding/add-org-members-tag`,
      headers: {
        secretkey: process.env.REACT_APP_PDF_FIX_SECRET
      },
      data: {
        orgId,
        isCustom: custom,
        newTag: tagName
      }
    };
    await api(config)
      .then((res) => {
        setCustom(false);
        setTagName('');
      })
      .catch((error) => {
        console.log(error);
      });
    const socialPageFlag =
      customSocialPage === undefined ? socialPage : customSocialPage;
    const leadPageFlag =
      customLeadPage === undefined ? leadPage : customLeadPage;
    const emailSignaturePageFlag =
      customEmailSignaturePage === undefined
        ? emailSignaturePage
        : customEmailSignaturePage;
    const microsoftSSOFlag =
      microsoftSSO === undefined ? hasMicrosoftSSO : microsoftSSO;
    const headerFlag = header === undefined ? isHeaderSnippet : Boolean(header);
    const bodyFlag = body === undefined ? isBodySnippet : Boolean(body);
    const allowInstructionAndTaggingFlag =
      customAllowInstructionAndTagging === undefined
        ? allowInstructionAndTagging
        : customAllowInstructionAndTagging;
    const isEDOflag = EDO === undefined ? isEDO : EDO;
    if (legacy && !isNotLegacy) {
      api
        .post(
          `${process?.env?.REACT_APP_SERVER_URL_V2}/orders/upgrade-organization`,
          {
            orgId
          }
        )
        .catch((err) => {
          console.log(err);
        });
    }
    firebase
      .firestore()
      .collection('organizations')
      .doc(orgId)
      .update({
        customSocialPage: socialPageFlag,
        customLeadPage: leadPageFlag,
        customEmailSignaturePage: emailSignaturePageFlag,
        linkModes:
          !linkModes || linkModes[0] === 'Not Selected' ? null : linkModes,
        allowInstructionAndTagging: allowInstructionAndTaggingFlag,
        isMicrosoftSsoMandatory: microsoftSSOFlag,
        isHeaderSnippet: headerFlag,
        isBodySnippet: bodyFlag,
        isEDO: isEDOflag
      })
      .then(() => {
        message.success('Settings updated successfully');
        setLoading(false);
        setIsHeaderSnippetUpdated(false);
        setIsBodySnippetUpdated(false);
        setVisible(false);
        if (
          linkModes[0] !== 'Not Selected' &&
          linkModes[0] !== prevLinkMode[0]
        ) {
          const data = {
            orgId: orgId,
            linkMode: linkModes[0]
          };
          const config = {
            method: 'post',
            url: `${process?.env?.REACT_APP_SERVER_URL_V2}/org/update-mode`,
            data: data
          };
          api(config).catch((error) => {
            console.log(error);
          });
        }
        form.resetFields();
      });
    const edoConfig = {
      method: 'post',
      url: `${process?.env?.REACT_APP_API_GTW_URL}/v1/settings`,
      data: {
        category: 'organization',
        constraint: 'setDefault',
        key: 'purchaseType',
        mediaTypes: [],
        objId: orgId,
        value: isEDOflag ? 'EDO' : 'default',
        webLink: 'null'
      }
    };
    api(edoConfig);
    if (showViralLink !== undefined) postViralLinkSetting(showViralLink);
  };
  const getTagSettings = async () => {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/org-onboarding/get-org-members-tag?orgId=${orgId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            secretkey: process.env.REACT_APP_PDF_FIX_SECRET
          }
        };
        api(config)
          .then((res) => {
            setCustom(res?.data?.data?.isCustomTag);
            setTagName(res?.data?.data?.tagName);
            setTipsAndTricks(res?.data?.data?.tipsAndTricks);
            if (res?.data?.data?.postShipping) {
              setEmailCommunicationType((prev) => [...prev, 'postShipping']);
            }
            if (res?.data?.data?.preShipping) {
              setEmailCommunicationType((prev) => [...prev, 'preShipping']);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };
  const getBrandedLinkSettings = async () => {
    setSettingsLoading(true);
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_SERVER_URL}/v2/org/branded-links/${orgId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios(config)
      .then((res) => {
        setIsBrandedLinkUsed(res?.data?.data?.isBrandedLinkUsed);
        setBrandedLinkDomain(res?.data?.data?.brandedLinkDomain);
        setIsBrandedLinkDomainVerified(
          res?.data?.data?.isBrandedLinkDomainVerified
        );
        setIsDisableInput(
          res?.data?.data?.brandedLinkDomain?.length > 0 ||
            res?.data?.data?.isBrandedLinkDomainVerified
        );
        setSettingsLoading(false);
      })
      .catch((error) => {
        catchErrorInSentry(error?.response?.data?.message);
        console.log(error);
        setSettingsLoading(false);
      });
  };

  const handleBrandedLinkStatus = async (isActive) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/v2/org/branded-links/status`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        orgId,
        isBrandedLinkUsed: isActive
      }
    };
    axios(config)
      .then((res) => {
        message.success(res?.data?.message);
      })
      .catch((error) => {
        catchErrorInSentry(error);
        console.log(error);
      });
  };

  const handleAddRemoveDomain = async (isAdd) => {
    if (isAdd) {
      setAddingBrandedLink(true);
    } else {
      setRemovingBrandedLink(true);
    }
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/v2/org/branded-links/domain`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        orgId,
        isAdd,
        domain: brandedLinkDomain
      }
    };
    axios(config)
      .then((res) => {
        message.success(res?.data?.message);
        getBrandedLinkSettings();
        if (isAdd) {
          setIsDisableInput(true);
          setAddingBrandedLink(false);
        } else {
          setIsDisableInput(false);
          setRemovingBrandedLink(false);
        }
      })
      .catch((error) => {
        catchErrorInSentry(error);
        if (isAdd) {
          setAddingBrandedLink(false);
        } else {
          setRemovingBrandedLink(false);
        }
        console.log(error);
      });
  };

  const handleVerifyBrandedLink = async () => {
    setVerifyingBrandedLink(true);
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/v2/org/branded-links/verify`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        orgId
      }
    };
    axios(config)
      .then((res) => {
        message.success(res?.data?.message);
        getBrandedLinkSettings();
        setVerifyingBrandedLink(false);
      })
      .catch((error) => {
        catchErrorInSentry(error?.response?.data?.message);
        message.success(error?.response?.data?.message);
        setVerifyingBrandedLink(false);
        console.log(error);
      });
  };

  const showModal = () => {
    setVisible(true);
    getBrandedLinkSettings();
    getTagSettings();
  };
  function getSettings() {
    firebase
      .firestore()
      .collection('organizations')
      .doc(orgId)
      .onSnapshot((org) => {
        const data = org?.data();
        setLeadPage(data ? data?.customLeadPage || false : false);
        setEmailSignaturePage(
          data ? data?.customEmailSignaturePage || false : false
        );
        setSocialPage(data ? data?.customSocialPage || false : false);
        setHasLeadGen(data ? data?.hasLeadGen || false : false);
        setHasEmailSignaturePage(
          data ? data?.hasEmailSignaturePage || false : false
        );
        setAllowInstructionAndTagging(
          org?.data()?.allowInstructionAndTagging ?? true
        );
        setHasSocialPage(data ? data?.hasSocialPage || false : false);
        setIsNotLegacy(data ? data?.isNotLegacy || false : false);
        const defaultLinkMode =
          org?.data()?.linkModes === null
            ? ['Not Selected']
            : org?.data()?.linkModes;
        setLinkModes(defaultLinkMode);
        setPrevLinkMode(defaultLinkMode);
        setHasMicrosoftSSO(data?.isMicrosoftSsoMandatory || false);
        setIsHeaderSnippet(data?.isHeaderSnippet || false);
        setIsBodySnippet(data?.isBodySnippet || false);
        setIsEDO(org?.data()?.isEDO || false);
      });
    getCurrentViralLinkShowState();
  }
  const getCurrentViralLinkShowState = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_GTW_URL}/v1/settings/organization/${orgId}/constraint/setDefault`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const settings = response?.data?.data || [];
      const viralLinkSetting = settings.find(
        (setting) => setting.key === 'viralLink'
      );
      // Check if the object is found and retrieve its value
      if (viralLinkSetting) {
        const viralLinkValue = viralLinkSetting.value;
        setShowViralLink(viralLinkValue);
      }
    } catch (error) {
      setShowViralLink(true);
    }
  };

  const selectLinkModesHandler = (linkMode) => {
    setLinkModes([linkMode]);
  };

  const postViralLinkSetting = async (value) => {
    const axios = require('axios');
    let data = JSON.stringify({
      key: 'viralLink',
      value: value,
      category: 'organization',
      objId: orgId,
      mediaTypes: [],
      constraint: 'setDefault',
      webLink: null
    });
    const token = await firebase.auth().currentUser.getIdToken(true);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_GTW_URL}/v1/settings/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        setShowViralLink(value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSettings();
    getEditorData();
    getToken();
  }, []);

  const uploadCodeSnippet = async () => {
    const config = {
      method: 'post',
      url: `${process?.env?.REACT_APP_SERVER_URL_V2}/org/snippet`
    };

    const headerData = {
      orgId: orgId,
      placement: 'header',
      code: headerSnippet
    };

    const bodyData = {
      orgId: orgId,
      placement: 'body',
      code: bodySnippet
    };
    Promise.all([
      api({ ...config, data: headerData }),
      api({ ...config, data: bodyData })
    ])
      .then((responses) => {
        const [headerResp, bodyResp] = responses;
        if (headerResp && bodyResp) {
          setSnippetLoader(false);
        }
      })
      .catch((err) => console.log(err?.message));
  };

  const getEditorData = async () => {
    setSnippetLoader(true);
    const resp = await getCodeSnippet(orgId);
    const { headerResp, bodyResp } = resp;
    if (headerResp && bodyResp) {
      if (headerResp?.data) {
        setHeaderSnippet(headerResp?.data?.snippet);
      }
      if (bodyResp?.data) {
        setBodySnippet(bodyResp?.data?.snippet);
      }
      setSnippetLoader(false);
    }
  };

  const headerSnippetHandler = async (isHeader) => {
    setIsHeaderSnippet(isHeader);
    if (!isHeader) setHeaderSnippet('');
  };

  const bodySnippetHandler = (isBody) => {
    setIsBodySnippet(isBody);
    if (!isBody) setBodySnippet('');
  };

  const headerTextHandler = (e) => {
    setIsHeaderSnippetUpdated(true);
    setHeaderSnippet(e?.target?.value);
  };

  const bodyTextHandler = (e) => {
    setIsBodySnippetUpdated(true);
    setBodySnippet(e?.target.value);
  };

  const handleEmailCommunication = async () => {
    await api.post(
      `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/MAT/org-onboarding/add-tags`,
      {
        orgId: orgId,
        preShipping: emailCommunicationType.includes('preShipping') ?? false,
        postShipping: emailCommunicationType.includes('postShipping') ?? false,
        tipsAndTricks
      },
      { headers: { secretkey: process.env.REACT_APP_PDF_FIX_SECRET } }
    );
  };
  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Settings
      </Button>
      <Modal
        title="Settings"
        visible={visible}
        width="730px"
        footer={null}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setCustom(false);
          setTagName('');
        }}
      >
        <Alert
          message="Upload zip file for social page and handlebars file for lead generation!"
          type="warning"
        />
        <Row
          justify="space-between"
          align="middle"
          style={{ margin: '15px 0px' }}
        >
          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, 'social', setSocialPageLoading);
            }}
          >
            <Button
              loading={socialPageLoading}
              icon={
                hasSocialPage ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasSocialPage ? 'Re-upload' : 'Upload'} social page
            </Button>
          </Upload>
          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, 'lead', setLeadGenLoading);
            }}
          >
            <Button
              loading={leadGenLoading}
              icon={
                hasLeadGen ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasLeadGen ? 'Re-upload' : 'Upload'} lead generation
            </Button>
          </Upload>

          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, 'email', setEmailLoading);
            }}
          >
            <Button
              loading={emailLoading}
              icon={
                hasEmailSignaturePage ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasEmailSignaturePage ? 'Re-upload' : 'Upload'} email signature
            </Button>
          </Upload>
        </Row>
        {settingsLoading ? (
          <div className="container-loader">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            name="basic"
            onFinish={handleSubmit}
            initialValues={{ header: headerSnippet, body: bodySnippet }}
          >
            <Card
              title="Organization"
              headStyle={{ backgroundColor: '#FAFAFA' }}
              style={{ margin: '15px 0px' }}
            >
              <Form.Item
                label="Upgrade"
                name="legacy"
                tooltip={{
                  title:
                    "Irreversible switch. Once set, you won't be able to change it.",
                  icon: <InfoCircleOutlined />
                }}
              >
                <Switch disabled={isNotLegacy} defaultChecked={isNotLegacy} />
              </Form.Item>
              <Row justify="space-between">
                <Form.Item label="Microsoft SSO" name="microsoftSSO">
                  <Switch defaultChecked={hasMicrosoftSSO} />
                </Form.Item>
                <Form.Item label="Link modes" name="linkModes">
                  <Select
                    defaultValue={!linkModes ? 'Not Selected' : linkModes[0]}
                    style={{ width: 150 }}
                    onChange={selectLinkModesHandler}
                  >
                    <Option value="Not Selected">Not Selected</Option>
                    <Option value="Business Card">Business Card</Option>
                    <Option value="Social Profile">Social Profile</Option>
                    <Option value="Lead Generation">Lead Generation</Option>
                  </Select>
                </Form.Item>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Form.Item
                    label="Allow Instruction And Tagging"
                    name="customAllowInstructionAndTagging"
                  >
                    <Switch defaultChecked={allowInstructionAndTagging} />
                  </Form.Item>
                </Col>
                <Col>
                  {allowInstructionAndTagging && (
                    <Button
                      onClick={() => {
                        setIsPoopupOpen(true);
                      }}
                    >
                      Send Emails
                    </Button>
                  )}
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Form.Item label="Show Viral Link" name="showViralLink">
                    <Switch defaultChecked={showViralLink} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>
                  <Form.Item label="Custom">
                    <Switch
                      checked={custom}
                      onChange={(e) => {
                        setCustom(e);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {custom && (
                    <Input
                      placeholder="Enter tag here"
                      onChange={(e) => {
                        setTagName(e?.target?.value);
                      }}
                      value={tagName}
                    />
                  )}
                </Col>
              </Row>
              <Form.Item label="Add Domain" name="isBrandedLinkUsed">
                <Switch
                  checked={isBrandedLinkUsed}
                  defaultChecked={isBrandedLinkUsed}
                  onChange={(e) => {
                    setIsBrandedLinkUsed(e);
                    if (brandedLinkDomain?.length > 0) {
                      handleBrandedLinkStatus(e);
                    }
                  }}
                />
              </Form.Item>
              {isBrandedLinkUsed && (
                <div>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Input
                        placeholder="Enter domain"
                        value={brandedLinkDomain}
                        onChange={(e) => {
                          setBrandedLinkDomain(e?.target?.value);
                        }}
                        disabled={isDisableInput}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleAddRemoveDomain(true);
                        }}
                        disabled={brandedLinkDomain?.length < 1}
                        loading={addingBrandedLink}
                      >
                        Add
                      </Button>
                    </Col>
                    <Col span={4}>
                      {isDisableInput && (
                        <Button
                          type="primary"
                          onClick={() => {
                            if (brandedLinkDomain?.length > 0) {
                              handleVerifyBrandedLink();
                            }
                          }}
                          disabled={isBrandedLinkDomainVerified}
                          icon={
                            isBrandedLinkDomainVerified && (
                              <CheckCircleOutlined />
                            )
                          }
                          loading={verifyingBrandedLink}
                        >
                          {isBrandedLinkDomainVerified ? 'Verified' : 'Verify'}
                        </Button>
                      )}
                    </Col>
                    <Col span={5}>
                      {brandedLinkDomain?.length > 0 && isDisableInput && (
                        <Button
                          danger
                          onClick={() => {
                            handleAddRemoveDomain(false);
                          }}
                          loading={removingBrandedLink}
                        >
                          Remove
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Descriptions
                    column={1}
                    title="Please set your DNS config as below"
                  >
                    <Descriptions.Item label="Input Domain">
                      {process.env.REACT_APP_BRANDED_LINK_INPUT_DOMAIN}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                      {BRANDED_LINK_TYPE}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
              <Form.Item label="EDO" name="EDO">
                <Switch defaultChecked={isEDO} />
              </Form.Item>
            </Card>

            <Card
              title="Custom"
              headStyle={{ backgroundColor: '#FAFAFA' }}
              style={{ margin: '0px 0px 15px 0px' }}
            >
              <Row justify="space-between">
                <Form.Item label="Custom social page" name="customSocialPage">
                  <Switch
                    disabled={!hasSocialPage}
                    defaultChecked={socialPage}
                  />
                </Form.Item>
                <Form.Item label="Custom lead generation" name="customLeadPage">
                  <Switch disabled={!hasLeadGen} defaultChecked={leadPage} />
                </Form.Item>
              </Row>
              <Form.Item
                label="Custom Email Signature Page"
                name="customEmailSignaturePage"
              >
                <Switch
                  disabled={!hasEmailSignaturePage}
                  defaultChecked={emailSignaturePage}
                />
              </Form.Item>

              <Card
                title={'Snippet </>'}
                headStyle={{ backgroundColor: '#FAFAFA' }}
                style={{ margin: '0px 0px 15px 0px' }}
              >
                <Spin spinning={snippetLoader}>
                  <Space direction="horizontal">
                    <Text>Header</Text>
                    <Switch
                      onChange={headerSnippetHandler}
                      defaultChecked={isHeaderSnippet}
                    />
                  </Space>
                  <Form.Item name="header">
                    {isHeaderSnippet && (
                      <TextArea
                        style={{ marginTop: '10px' }}
                        rows={8}
                        placeholder="Enter your head part here"
                        onChange={headerTextHandler}
                      />
                    )}
                  </Form.Item>
                  <Space direction="horizontal">
                    <Text>body</Text>
                    <Switch
                      onChange={bodySnippetHandler}
                      defaultChecked={isBodySnippet}
                    />
                  </Space>
                  <Form.Item name="body">
                    {isBodySnippet && (
                      <TextArea
                        style={{ marginTop: '10px' }}
                        rows={8}
                        placeholder="Enter your body part here"
                        onChange={bodyTextHandler}
                      />
                    )}
                  </Form.Item>
                </Spin>
              </Card>
            </Card>

            <Form.Item {...tailLayout}>
              <Button loading={loading} type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        )}
        <Modal
          title="Email Communication"
          visible={isPoopupOpen}
          okText="Send"
          onOk={() => {
            handleEmailCommunication();
            setIsPoopupOpen(false);
          }}
          onCancel={() => {
            setIsPoopupOpen(false);
          }}
        >
          <Space direction="vertical">
            <Row>
              <Col>
                <Checkbox defaultChecked disabled={allowInstructionAndTagging}>
                  Login Instruction
                </Checkbox>
              </Col>
              <Col className="email-communication-dropdown">
                <Select
                  mode="multiple"
                  onChange={(e) => {
                    setEmailCommunicationType(e);
                  }}
                  defaultValue={emailCommunicationType}
                >
                  {EMAIL_ONBOARDING_TYPE?.map((onBoardingType) => {
                    return (
                      <Select.Option value={onBoardingType?.type}>
                        {onBoardingType?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Checkbox
              checked={tipsAndTricks}
              onChange={(e) => {
                setTipsAndTricks(e?.target?.checked);
              }}
            >
              Tips & Tricks
            </Checkbox>
          </Space>
        </Modal>
      </Modal>
    </>
  );
}

export default Settings;
