import React, { useState, useEffect } from 'react';
import { useParams, Link, withRouter, useHistory } from 'react-router-dom';
import JSZip from 'jszip';
import axios from 'axios';

import { Typography, Button, Table, Card, Space, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { lowerCase, capitalize, flatten } from 'lodash';
import { firebase } from '../../../firebase';
import Loader from '../../Loader/Loader';
import { generateDate } from '../../../utils/generateDate';
import { catchErrorInSentry, formOrderNumber } from '../../../common/utils';
import AddressForm from '../../Vendor/Shipper/InWarehouse/AddressForm';

function OrderSets() {
  let { id } = useParams();
  const [sets, setSets] = useState();
  const [order, setOrder] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [orderTrackingUrl, setOrderTrackingUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [assetsToDownload, setAssetsToDownload] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [designSetId, setDesignSetId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [pdfUrls, setPdfUrls] = useState(null);
  const history = useHistory();

  const ref = firebase
    .firestore()
    .collection('sets')
    .where('orderUniqueId', '==', id);

  const orderRef = firebase
    .firestore()
    .collection('orders')
    .where('orderUniqueId', '==', id);

  const cardRef = firebase
    .firestore()
    .collection('cards')
    .where('orderUniqueId', '==', id);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'setUniqueId',
      key: 'setUniqueId',
      render: (text) => {
        return <Link to={`/v2-orders/${id}/${text}`}>{text}</Link>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        if (text)
          if (text?.length)
            return (
              <Typography.Text>{text?.replace(/_/g, ' ')}</Typography.Text>
            );
      }
    },
    {
      title: 'Owner email',
      dataIndex: 'owner',
      key: 'email'
    },
    {
      title: 'No. of cards',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];

  const historyColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => capitalize(lowerCase(text))
    },
    {
      title: 'Current Action',
      dataIndex: 'current_action',
      key: 'current_action',
      render: (text) => capitalize(lowerCase(text))
    },
    {
      title: 'Time Required',
      dataIndex: 'time_required',
      key: 'time_required'
    },
    {
      title: 'Last Updated At',
      dataIndex: 'recorded_at',
      key: 'recorded_at',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      },
      sorter: (a, b) => a?.recorded_at?.seconds - b?.recorded_at?.seconds,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend'
    }
  ];

  function getSet() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((set) => {
        items.push(set?.data());
        setDesignSetId(set?.data()?.designSetId);
      });
      setSets(items);
      setLoading(false);
    });
  }

  function getOrder() {
    setLoading(true);
    orderRef.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setOrder(doc?.data());
        setOwnerId(doc?.data()?.user);
      });
      setLoading(false);
    });
  }
  async function getOrderHistory() {
    const historyArr = [];
    try {
      const order = await orderRef.get();
      for (const o of order.docs) {
        if (o?.data()?.orderShippingDetails?.orderTrackingUrl) {
          setOrderTrackingUrl(
            o?.data()?.orderShippingDetails?.orderTrackingUrl
          );
        }
        const history = await firebase
          .firestore()
          .collection('orders')
          .doc(o?.id)
          .collection('order_history')
          .get();
        for (const h of history?.docs) {
          historyArr.push(h?.data());
        }
      }
      setHistoryData(historyArr);
    } catch (e) {
      catchErrorInSentry(e);
      console.log(e);
    }
  }
  const getCards = async () => {
    const response = await cardRef.get();
    const storageSignedUrl = [];
    response.docs.forEach((card) => {
      storageSignedUrl.push(card?.data()?.storageSignedUrl);
    });
    setPdfUrls(storageSignedUrl);
  };

  const getCardAssets = async () => {
    const response = await firebase
      .firestore()
      .collection('designSet')
      .doc(designSetId)
      .get();
    const assets = [];
    response.data().cardDesign.forEach((cardData) => {
      assets.push({
        front: cardData?.frontBGImageUrl ?? cardData?.frontImageUrl,
        back: cardData?.backBGImageUrl ?? cardData?.backImageUrl,
        qrCode: cardData?.qrReference
      });
    });
    setAssetsToDownload(assets);
  };

  const getProfileInfo = async () => {
    const profileData = await firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', ownerId)
      .get();
    profileData.docs.forEach((profile) => {
      if (profile?.data()?.orgId) {
        setOrgId(profile?.data()?.orgId);
      } else {
        setOrgId();
      }
      if (profile?.data()?.avatarUrl?.length > 0) {
        setAvatarUrl(profile?.data()?.avatarUrl);
      }
    });
  };
  const getOrgData = async () => {
    const orgData = await firebase
      .firestore()
      .collection('organizations')
      .doc(orgId)
      .get();
    if (orgData?.data()?.logoUrl?.length > 0) {
      setCompanyLogo(orgData?.data()?.logoUrl);
    }
  };
  useEffect(() => {
    getOrder();
    getSet();
    getOrderHistory();
    getCards();
  }, []);

  useEffect(() => {
    if (designSetId) {
      getCardAssets();
    }
  }, [designSetId]);

  useEffect(() => {
    if (ownerId) {
      getProfileInfo();
    }
  }, [ownerId]);

  useEffect(() => {
    if (orgId) {
      getOrgData();
    }
  }, [orgId]);

  if (loading) {
    return <Loader />;
  }

  const handleAssetsDownload = async () => {
    try {
      setDownloading(true);
      const zip = new JSZip();
      const flatArray = assetsToDownload.reduce((acc, item) => {
        acc.push(item?.front, item?.back, item?.qrCode);
        return acc;
      }, []);
      if (orgId && companyLogo?.length > 0) {
        flatArray.push(companyLogo);
      }
      if (avatarUrl) {
        flatArray.push(avatarUrl);
      }
      flatArray.push(pdfUrls);
      if (flatArray) {
        const responses = await Promise.all(
          flatten(flatArray).map(async (item) => {
            const response = await axios.get(item, {
              responseType: 'arraybuffer'
            });
            return { item, data: response };
          })
        );
        responses.forEach(({ item, data }) => {
          const urlObject = new URL(item);
          let fileName = urlObject?.pathname?.split('/').pop();
          if (!fileName.endsWith('.png') && !fileName.endsWith('.pdf')) {
            fileName = `${fileName.trim()}.png`;
          }
          zip.file(fileName, data?.data);
        });

        const content = await zip.generateAsync({ type: 'blob' });
        const zipFile = new Blob([content]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipFile);
        link.download = 'images.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloading(false);
      }
    } catch (error) {
      message.error('Something went wrong');
      setDownloading(false);
      console.log(error);
    }
  };

  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="text"
            icon={<LeftOutlined />}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {id}
          </Typography.Title>
        </Space>
      }
    >
      <Card
        title="Sets"
        size="large"
        style={{ overflow: 'auto' }}
        extra={
          order?.orderCreatedFrom === 'REACT_WC' && (
            <Button
              type="primary"
              onClick={handleAssetsDownload}
              loading={downloading}
            >
              Download Assets
            </Button>
          )
        }
      >
        <Table dataSource={sets} columns={columns} pagination={false} />
      </Card>
      <Card
        title="Order History"
        size="large"
        style={{ overflow: 'auto' }}
        extra={
          <>
            <div>
              Tracking Link :
              {orderTrackingUrl ? (
                <Button
                  type="link"
                  onClick={() => {
                    window.open(orderTrackingUrl, '_blank');
                  }}
                >
                  Here
                </Button>
              ) : (
                'Not available'
              )}
            </div>
            <div>
              <AddressForm record={order} orderUniqueId={id} isAdminPortal />
            </div>
          </>
        }
        className="order-history-card"
      >
        <Table
          dataSource={historyData}
          columns={historyColumns}
          pagination={false}
        />
      </Card>
    </Card>
  );
}

export default withRouter(OrderSets);
