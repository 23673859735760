import React from 'react';

function MemberRole({ role }) {
  if (role === 'superAdmin') {
    return <span className="super-admin">OA</span>;
  } else if (role === 'admin') {
    return <span className="admin">A</span>;
  }
  return null;
}

export default MemberRole;
