import React, { useEffect, useState } from 'react';

import { Input, List, message, Popover, Switch, Typography } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { firebase } from '../../../firebase';
import { ADMIN_DEPT_ID } from '../../../common/constant';
import sortBy from 'lodash/sortBy';
import Highlighter from 'react-highlight-words';
import MemberRole from './MemberRole';
import { catchErrorInSentry } from '../../../common/utils';

function AdminDropdown({ memberId, record }) {
  const [isOpen, setIsOpen] = useState(false);
  const [deptList, setDeptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orgAdmin, setOrgAdmin] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [role, setRole] = useState('');

  const handleAdmin = async (e, deptId) => {
    const token = await firebase.auth().currentUser.getIdToken(true);
    try {
      setLoading(true);
      const response = await Axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/v2/dept/update-dept-admin/${memberId}`,
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        },
        data: { deptId: deptId, action: e }
      });
      setLoading(false);
      message.success(response?.data);
      getListOfDept();
    } catch (error) {
      catchErrorInSentry(error?.response?.data?.message);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (record?.isOrgAdmin === true) {
      setRole('superAdmin');
    } else if (!record?.isOrgAdmin && record?.deptAdmin?.length > 0) {
      setRole('admin');
    } else {
      setRole();
    }
  }, [record]);

  const content = (
    <div>
      <div className="admin-access-switch mb-8">
        <Typography.Text strong>Organization Admin</Typography.Text>
        <Switch
          checked={orgAdmin}
          onChange={(e) => {
            setOrgAdmin(e);
            handleAdmin(e, ADMIN_DEPT_ID);
          }}
        />
      </div>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={deptList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <div className="admin-access-switch">
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                      item.deptName ? item?.deptName?.toString() : ''
                    }
                  />
                  <Switch
                    onChange={(e) => {
                      handleAdmin(e, item?.deptId);
                    }}
                    checked={item?.isAdmin}
                  />
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
  const getListOfDept = async () => {
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken(true);
    try {
      const data = await Axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/v2/dept/get-dept-by-user/${memberId}`,
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      });
      setOrgAdmin(data?.data?.isOrgAdmin);
      setDeptList(data?.data?.deptInfo);
      setLoading(false);
    } catch (error) {
      catchErrorInSentry(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getListOfDept();
    }
  }, [isOpen]);
  const searchDept = (string) => {
    let searchResult = sortBy(deptList, [
      function (dept) {
        if (dept?.deptName?.toLowerCase()?.includes(string?.toLowerCase()))
          return dept?.deptName;
      }
    ]);
    setDeptList(searchResult);
  };
  const handleOpenState = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Popover
      content={content}
      title={
        <Input
          placeholder="Enter group name"
          bordered={false}
          addonBefore={<SearchOutlined style={{ color: '#F84848' }} />}
          onChange={(e) => {
            setSearchText(e?.target?.value);
            searchDept(e?.target?.value);
          }}
        />
      }
      trigger="click"
      onVisibleChange={handleOpenState}
    >
      <Typography.Link
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Access {isOpen ? <DownOutlined rotate={180} /> : <DownOutlined />}
        <MemberRole role={role} />
      </Typography.Link>
    </Popover>
  );
}

export default AdminDropdown;
