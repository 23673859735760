import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { SyncOutlined, UpCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { firebase } from '../../../firebase';
import AddOrganization from './AddOrganization';
import { ACTION_TYPE, LIMIT_PRIMARY } from '../../../common/constant';
import './organisation.css';
import Search from './SearchOrg';
import axios from 'axios';
import { scrollToTop } from '../../../common/utils';
import api from '../../../common/api';

function GetLicense({ record }) {
  const [spin, setSpin] = useState(false);
  const [count, setCount] = useState(0);
  const licenseRef = firebase.firestore().collection('licenses');
  useEffect(() => {
    if (record?.id) {
      licenseRef
        .where('orgId', '==', record?.id)
        .where('isExpired', '==', false)
        .get()
        .then((res) => {
          setCount(res?.size);
        })
        .finally(() => {
          setSpin(false);
        });
    } else {
      setSpin(false);
    }
  }, [record?.id]);
  return spin ? <Spin /> : count;
}
function Organisation() {
  const [organisation, setOrganisation] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState();
  const [loading, setLoading] = useState(false);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [sorting, setSorting] = useState('asc');
  let ref = firebase.firestore().collection('organizations');
  ref = ref.orderBy('name', sorting);
  ref = ref.limit(LIMIT_PRIMARY);

  const getProfiles = async (orgId) => {
    const data = await firebase
      .firestore()
      .collection('profiles')
      .where('orgId', '==', orgId)
      .get();
    const items = [];
    data.docs.forEach((doc) => {
      items.push({ ...doc?.data(), docId: doc?.id });
    });
    return items;
  };

  const getOrganisation = async (skip) => {
    try {
      setLoading(true);
      await api
        .get(
          `${process.env.REACT_APP_SERVER_URL_V2}/org/get-all-organizations?${
            skip ? `skip=${skip}` : ''
          }&limit=${LIMIT_PRIMARY}&order=${sorting}`
        )
        .then((res) => {
          setLoading(false);
          if (skip) {
            setOrganisation([...organisation, ...res?.data?.data]);
          } else {
            setOrganisation(res?.data?.data);
          }
          setSkip(res?.data?.data.slice(-1)[0]?.orgId);
          if (res?.data?.data?.length < LIMIT_PRIMARY) {
            setHasMore(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text, record) => {
        return (
          <Link to={`/v2-organizations/${record?.orgId || record?.id}`}>
            {text}
          </Link>
        );
      }
    },
    {
      title: 'Members',
      dataIndex: 'memberCount',
      key: 'memberCount',
      render: (text, record) => {
        return <Typography.Text>{record?.memberCount}</Typography.Text>;
      }
    },
    {
      title: 'Licenses Owned',
      dataIndex: 'licenseCount',
      key: 'licenseCount',
      render: (text, record) => {
        if (text) {
          return <Typography.Text>{text}</Typography.Text>;
        }
        return <GetLicense record={record} />;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      render: function Delete(text, record) {
        return (
          <Space>
            <Button
              type="text"
              icon={<SyncOutlined />}
              onClick={async () => {
                const syncedMembers = [];
                const syncedAdmins = [];
                const data = await getProfiles(record?.orgId || record?.id);
                data.forEach((profile) => {
                  if (profile?.orgId === record?.orgId || record?.id) {
                    syncedMembers.push(profile?.docId);
                    if (profile?.isOrgAdmin) {
                      syncedAdmins.push(profile?.docId);
                    }
                  }
                });
                firebase
                  .firestore()
                  .collection('organizations')
                  .doc(record?.orgId || record?.id)
                  .update({ members: syncedMembers, admins: syncedAdmins })
                  .then(() => {
                    const body = {
                      app_id: process?.env?.REACT_APP_FIREBASE_PROJECT_ID
                    };
                    axios
                      .post(
                        `${process.env.REACT_APP_SERVER_URL_V2}/algolia/token`,
                        body
                      )
                      .then((res) => {
                        if (res?.data?.data) {
                          axios.post(
                            `${process.env.REACT_APP_SERVER_URL_V2}/algolia/sync`,
                            {
                              type:
                                process?.env?.REACT_APP_ALGOLIA_INDEX ||
                                'organizations',
                              action: ACTION_TYPE?.UPDATE,
                              id: record?.orgId || record?.id
                            },
                            {
                              headers: {
                                authorization: `Bearer ${res?.data?.data}`
                              }
                            }
                          );
                        }
                      });
                    message.destroy();
                    message.success('Members synced successfully');
                  });
              }}
            />
          </Space>
        );
      }
    }
  ];

  useEffect(() => {
    getOrganisation(false);
  }, [sorting]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.scrollY > 300);
    });
  }, []);
  const handleTableChange = (pagination, filter, sorter) => {
    if (!isEmpty(sorter)) {
      const { order, columnKey } = sorter;
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case 'name':
              setSorting('asc');
              break;

            default:
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case 'name':
              setSorting('desc');
              break;

            default:
              break;
          }
          break;

        default:
          setSorting('asc');
      }
    }
  };
  return (
    <Card title="Organization" extra={[<AddOrganization />]}>
      <Row>
        <Col span={24}>
          <Search
            organisation={organisation}
            setOrganisation={setOrganisation}
            columns={columns}
            loading={loading}
            onChange={handleTableChange}
            setHasMore={setHasMore}
            hasMore={hasMore}
          />
        </Col>
      </Row>
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getOrganisation(skip);
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default Organisation;
