import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  List,
  message,
  Popover,
  Row,
  Spin,
  Switch,
  Table,
  Tag,
  Typography
} from 'antd';
import {
  UpCircleOutlined,
  DeleteOutlined,
  DownOutlined
} from '@ant-design/icons';
import { LIMIT_PRIMARY, ACTION_TYPE } from '../../../common/constant';
import { firebase } from '../../../firebase';
import { generateDate } from '../../../utils/generateDate';
import confirm from 'antd/lib/modal/confirm';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import AdminDropdownV2 from './AdminDropdownV2';
import app from 'firebase/app';
import axios from 'axios';
import api from '../../../common/api';
import { catchErrorInSentry, scrollToTop } from '../../../common/utils';

function MembersTable({ orgId, memberIds }) {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState();
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let profileRef = firebase
    .firestore()
    .collection('profiles')
    .where('orgId', '==', orgId)
    .where('isDeleted', '==', false)
    .where('isDisabled', '==', false);

  if (searchText && searchedColumn) {
    if (searchedColumn === 'user') {
      profileRef = profileRef
        .orderBy(searchedColumn)
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  } else {
    profileRef = profileRef
      .orderBy('isOrgAdmin', 'desc')
      .orderBy('isDeptAdmin', 'desc')
      .orderBy('Full Name', 'asc');
  }
  let licenseRef = firebase.firestore().collection('licenses');
  const ref = firebase.firestore().collection('organizations').doc(orgId);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'fullName'
    },
    {
      title: 'Email ID',
      dataIndex: 'user',
      key: 'user',
      ...getColumnSearchPropsV2(
        'user',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      )
    },
    {
      title: 'Licenses Assigned',
      dataIndex: 'licenseAssigned',
      key: 'licenseAssigned',
      render: function GetLicense(text, record) {
        const [spin, setSpin] = useState(true);
        const [types, setTypes] = useState([]);
        useEffect(() => {
          if (record?.userId) {
            licenseRef
              .where('assignedTo', '==', record?.userId)
              .where('isExpired', '==', false)
              .get()
              .then((record) => {
                const items = [];
                record.forEach((card) => {
                  items.push(card?.data()?.type);
                });
                setTypes(items);
              })
              .finally(() => {
                setSpin(false);
              });
          } else {
            setSpin(false);
          }
        }, [record?.userId]);
        return spin ? (
          <Spin />
        ) : types?.length ? (
          <div>
            {types.map((type) => (
              <Tag>{type}</Tag>
            ))}
          </div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Expires On',
      dataIndex: 'expiresOn',
      key: 'expiresOn',
      render: function GetLicense(text, record) {
        const [isOpen, setIsOpen] = useState(false);
        const [dates, setDates] = useState([]);

        useEffect(() => {
          if (record?.userId) {
            licenseRef
              .where('assignedTo', '==', record?.userId)
              .where('isExpired', '==', false)
              .orderBy('expiry', 'asc')
              .get()
              .then((res) => {
                const item = [];
                res.forEach((card) => {
                  if (card?.data()?.expiry) {
                    item.push({
                      date: card?.data()?.expiry,
                      type: card?.data()?.type
                    });
                    return;
                  }
                });
                setDates(item);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }, [record?.userId]);
        const handleOpenState = () => {
          setIsOpen(!isOpen);
        };
        return (
          <Popover
            content={
              <List
                className="license-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={dates}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          {item?.type} - {generateDate(item?.date)}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            }
            trigger="click"
            onVisibleChange={handleOpenState}
          >
            {dates?.length > 0 ? (
              <Typography.Link
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                Check Expiry{' '}
                {isOpen ? <DownOutlined rotate={180} /> : <DownOutlined />}
              </Typography.Link>
            ) : (
              '-'
            )}
          </Popover>
        );
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'Phone Number (work)',
      key: 'Phone Number (work)'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: function GetLicense(text, record) {
        const [spin, setSpin] = useState(true);
        const [status, setStatus] = useState(false);
        useEffect(() => {
          if (record?.userId) {
            licenseRef
              .where('assignedTo', '==', record?.userId)
              .get()
              .then((res) => {
                res.forEach((card) => {
                  if (!card?.data()?.isExpired) {
                    setStatus(true);
                    return;
                  }
                });
              })
              .finally(() => {
                setSpin(false);
              });
          } else {
            setSpin(false);
          }
        }, [record?.userId]);
        return spin ? <Spin /> : status ? 'Active' : 'Expired';
      }
    },
    {
      title: 'Admin',
      key: 'isAdmin',
      render: function Admin(text, record) {
        return <AdminDropdownV2 memberId={record?.docId} record={record} />;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      render: function Delete(text, record) {
        const [loading, setLoading] = useState(false);

        function showConfirm() {
          confirm({
            title: `Are you sure you want to remove member '${record['Full Name']}'`,
            onOk() {
              setLoading(true);
              api
                .post(
                  `${process.env.REACT_APP_SERVER_URL_V2}/org/remove-member/${orgId}`,
                  { memberIds: [`${record?.userId}`] }
                )
                .then(() => {
                  const body = {
                    app_id: process?.env?.REACT_APP_FIREBASE_PROJECT_ID
                  };
                  axios
                    .post(
                      `${process?.env?.REACT_APP_SERVER_URL}/v2/algolia/token`,
                      body
                    )
                    .then((res) => {
                      if (res?.data?.data) {
                        axios.post(
                          `${process?.env?.REACT_APP_SERVER_URL}/v2/algolia/sync`,
                          {
                            type:
                              process?.env?.REACT_APP_ALGOLIA_INDEX ||
                              'organizations',
                            action: ACTION_TYPE?.UPDATE,
                            id: orgId
                          },
                          {
                            headers: {
                              authorization: `Bearer ${res?.data?.data}`
                            }
                          }
                        );
                      }
                    });
                  setLoading(false);
                  // licenses are removed when user is removed from an organisation
                  const config = {
                    method: 'post',
                    url: `${process?.env?.REACT_APP_SERVER_URL_V2}/license/clear`,
                    data: {
                      userId: record?.docId,
                      orgId
                    }
                  };
                  api(config).catch((error) => {
                    console.log(error);
                  });
                });
            },
            onCancel() {}
          });
        }
        return (
          <Button
            loading={loading}
            type="text"
            icon={<DeleteOutlined style={{ color: 'red' }} />}
            onClick={showConfirm}
          />
        );
      }
    }
  ];
  profileRef = profileRef.limit(LIMIT_PRIMARY);

  function getMembers() {
    setLoading(true);
    profileRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc?.data(), docId: doc?.id });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setMembers(items);
      setLoading(false);
    });
  }

  const getNextMember = async () => {
    try {
      profileRef = profileRef.startAfter(offset);
      profileRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc?.data(), docId: doc?.id });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setMembers([...members, ...items]);
      });
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };

  useEffect(() => {
    getMembers();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.scrollY > 300);
    });
  }, [orgId, searchText]);

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        bordered={true}
        dataSource={members}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            disabled={!hasMore}
            onClick={getNextMember}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-button"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default MembersTable;
