import { Button, Card, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import api from '../../../../common/api';
import { generateDate } from '../../../../utils/generateDate';
import { LIMIT_PRIMARY } from '../../../../common/constant';
import { DownloadOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';

const paginationProp = {
  pageSize: 10
};

function Jigs() {
  const history = useHistory();
  const [jigs, setJigs] = useState();
  const [pagination, setPagination] = useState(paginationProp);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const material = queryParams.get('material');

  const getAllJigs = async (fetchNext) => {
    try {
      setLoading(true);
      const response = await api?.get(
        `${
          process?.env?.REACT_APP_API_GTW_URL
        }/v1/print-ready/jigs?limit=${LIMIT_PRIMARY}${
          pagination?.cursor && fetchNext ? `&cursor=${pagination?.cursor}` : ''
        }${
          material === 'metal'
            ? `&cardType=Mobilo Metal`
            : `&cardType=Mobilo Wood`
        }`
      );
      setPagination({
        ...pagination,
        cursor: response?.data?.data?.pagination?.cursor
      });
      if (fetchNext) {
        setJigs([...jigs, ...response?.data?.data?.data]);
      } else {
        setJigs(response?.data?.data?.data);
      }
      setHasMore(response?.data?.data?.pagination?.hasMore);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllJigs(false);
  }, []);

  const handleDeleteJig = async (record) => {
    try {
      const response = await api.put(
        `${process?.env?.REACT_APP_API_GTW_URL}/v1/print-ready/jigs/${record?.id}`,
        {
          isDeleted: true
        }
      );
      if (response) {
        getAllJigs(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleJigDownload = (record) => {
    if (record) {
      window.open(record);
    }
  };
  const columns = [
    {
      title: 'Jig',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return generateDate(text);
      }
    },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Front Jig',
      render: (text, record) => {
        if (record?.front?.signedUrl) {
          return (
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                handleJigDownload(record?.front?.signedUrl);
              }}
            />
          );
        }
      }
    },
    {
      title: 'Back Jig',
      render: (text, record) => {
        if (record?.back?.signedUrl) {
          return (
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                handleJigDownload(record?.back?.signedUrl);
              }}
            />
          );
        }
      }
    },
    {
      title: 'Action',
      render: (text, record) => {
        function showConfirm() {
          confirm({
            title: 'Are you sure you want to remove this jig?',
            onOk() {
              handleDeleteJig(record);
            },
            okText: 'Confirm',
            onCancel() {}
          });
        }
        return (
          <div>
            <Tooltip title="Remove Jig">
              <Button
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  showConfirm();
                }}
                className="red-btn"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];
  return (
    <div>
      <Card
        title={
          <Typography.Text>
            <LeftOutlined
              onClick={() => {
                history.goBack();
              }}
              className="mr-16"
            />
            Jig List
          </Typography.Text>
        }
      >
        <Table
          columns={columns}
          dataSource={jigs}
          className="cursor-pointer"
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        {hasMore && (
          <div className="display-flex justify-center fill-width">
            <Button
              onClick={() => {
                getAllJigs(true);
              }}
            >
              Load More
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
}

export default Jigs;
