import {
  Badge,
  Button,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getNewMessageIndicator } from '../../../utils/getNewMessageIndicator';
import { firebase } from '../../../firebase';
import {
  MessageOutlined,
  MessageTwoTone,
  FlagOutlined,
  FlagFilled
} from '@ant-design/icons';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import { generateDate } from '../../../utils/generateDate';
import _isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { handleOrderLengthAndScrollOffset } from '../../../utils/handleOrderLengthAndScrollOffset';

function TableComponent({
  data,
  userId,
  loading,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  setHasMore,
  sorterInfo,
  setSorterInfo
}) {
  const searchInput = useRef(null);
  const location = useLocation();
  const { dispatch } = useContext(AppContext);
  const isInProgressTab =
    location?.pathname?.includes('inprogress') || location?.pathname === '/';
  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      ),

      render: function SetUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [active, setActive] = useState(false);
        const [spin, setSPin] = useState(true);
        getNewMessageIndicator(
          record?.orderDocId,
          userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        if (spin) return <Spin />;
        return (
          <Space>
            <Link
              to={`/design/sets/${record?.orderUniqueId}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  data?.length,
                  window?.scrollY,
                  searchText,
                  searchedColumn
                );
              }}
            >
              {text}
            </Link>
            <Link
              to={`/chat/${record?.orderUniqueId}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  data?.length,
                  window?.scrollY,
                  searchText,
                  searchedColumn
                );
              }}
            >
              <Tooltip title="Internal Chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone
                      twoToneColor="#52c41a"
                      style={{ color: 'green', margin: 2 }}
                    />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore,
        'orders'
      )
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail',
      ...getColumnSearchPropsV2(
        'customerEmail',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      )
    },
    {
      title: 'Card Type',
      dataIndex: 'cardType',
      key: 'cardType',
      filters: [
        {
          text: 'Custom Plastic',
          value: `Mobilo Card (custom)`
        },
        {
          text: 'Custom Metal	',
          value: 'Mobilo Metal'
        },
        { text: 'Custom Wood', value: 'Mobilo Wood' }
      ],
      filterMultiple: false
    },
    {
      title: isInProgressTab ? 'Design State' : 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'New', value: 'false' },
        { text: 'Edit', value: 'true' }
      ],
      filterMultiple: false,
      render: (text, record) => {
        if (isInProgressTab) {
          return (
            <Typography.Text>
              {record?.isOrderProceedWithPreviousDesign &&
              record?.isOrderRejectedWithPreviousDesign
                ? 'NEW'
                : record?.isRejected || record?.isReverted
                ? 'EDIT'
                : 'NEW'}
            </Typography.Text>
          );
        } else {
          return <Typography.Text>{text?.replace(/_/g, ' ')}</Typography.Text>;
        }
      }
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      filterMultiple: false,

      render: (text, record) => {
        const tags = [];
        try {
          const string = text?.toLowerCase();
          if (!string?.includes('card only')) {
            if (string?.includes('key fob'))
              tags.push({ text: '+ Key Fob', color: 'orange' });
            if (string?.includes('smart button'))
              tags.push({ text: '+ Smart Button', color: 'green' });
          }
        } catch {}
        return (
          <>
            {tags.map((tag) => {
              return (
                <Tag key={tag} color={tag?.color}>
                  {tag?.text}
                </Tag>
              );
            })}
          </>
        );
      }
    },
    {
      title: 'Received At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      },
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'bug',
      filters: [
        {
          text: 'Bug',
          value: true
        },
        {
          text: 'No bug',
          value: undefined || false
        }
      ],
      render: function Bug(text, record) {
        const [flag, setFlag] = useState(record?.bug || false);
        const [loading, setLoading] = useState(false);

        async function handleFlagClick() {
          setLoading(true);
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.orderDocId)
            .update({
              bug: !flag
            })
            .then(() => {
              setLoading(false);
            })
            .finally(() => {
              setLoading(false);
            });
        }
        return (
          <Space size={0}>
            <Spin spinning={loading}>
              <Tooltip title={'Report bug'}>
                {!record.bug ? (
                  <Button
                    type="text"
                    icon={<FlagOutlined />}
                    onClick={handleFlagClick}
                  />
                ) : (
                  <Button
                    type="text"
                    icon={<FlagFilled style={{ color: '#d33e48' }} />}
                    onClick={handleFlagClick}
                  />
                )}
              </Tooltip>
            </Spin>
          </Space>
        );
      }
    }
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    dispatch({ type: 'SET_CARDTYPE_FILTER', data: filters?.cardType?.[0] });
    dispatch({ type: 'SET_ACTION', data: filters?.action?.[0] });
    dispatch({ type: 'SET_VARIATION', data: filters?.variation?.[0] });
    dispatch({ type: 'SET_DESIGN_STATUS', data: filters?.status?.[0] });
    if (!_isEmpty(sorter)) {
      setSorterInfo(sorter);
      const { order, columnKey } = sorter;
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case 'orderNumber':
              dispatch({ type: 'SET_SORTING', data: 'asc' });
              dispatch({ type: 'SET_SORTCOLUMN', data: 'orderNumber' });
              break;
            case 'updatedAt':
              dispatch({ type: 'SET_SORTING', data: 'asc' });
              dispatch({ type: 'SET_SORTCOLUMN', data: 'updatedAt' });
              break;
            default:
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case 'orderNumber':
              dispatch({ type: 'SET_SORTING', data: 'desc' });
              dispatch({ type: 'SET_SORTCOLUMN', data: 'orderNumber' });
              break;
            case 'updatedAt':
              dispatch({ type: 'SET_SORTING', data: 'asc' });
              dispatch({ type: 'SET_SORTCOLUMN', data: 'updatedAt' });
              break;
            default:
              break;
          }
          break;

        default:
          dispatch({ type: 'SET_SORTING', data: 'desc' });
          dispatch({ type: 'SET_SORTCOLUMN', data: 'updatedAt' });
      }
    }
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      loading={loading}
      onChange={handleTableChange}
    />
  );
}

export default TableComponent;
