import React from 'react';
import { Layout, Image, Result, Typography } from 'antd';
import { Switch, Route, Link, BrowserRouter } from 'react-router-dom';
import { AuthContext } from '../Auth';
import OrdersV2 from './V2Admin/orders/Orders';
import HomeMenu from './V2Admin/Menu';
import UserMenu from './UserMenu';
import VendorMenu from './Vendor/Menu';
import DesignInProgress from './Vendor/Designer/InProgress';
import DesignWaitingForApproval from './Vendor/Designer/WaitingForApproval';
import DesignCompleted from './Vendor/Designer/Completed';
import DesignInProgressSet from './Vendor/Designer/InProgress/set';
import OrderSetsV2 from './V2Admin/orders/sets';
import OrderCardV2 from './V2Admin/orders/Card';
import PrintInProgress from './Vendor/Printer/InProgress';
import PrintCompleted from './Vendor/Printer/Completed';
import ShipInTransit from './Vendor/Shipper/InTransit';
import ShipInWarehouse from './Vendor/Shipper/InWarehouse';
import ShipCompleted from './Vendor/Shipper/Completed';
import ShipPickup from './Vendor/Shipper/Pickup';
import PrivateRoute from '../PrivateRoute';
import Login from './Login';
import Dashboard from './Dashboard';
import AssignCard from './Vendor/Printer/InProgress/assignCard';
import AddDesign from './Vendor/Designer/InProgress/addDesign';
import InternalChat from './InternalChat';
import cards from './Vendor/Printer/cards';
import GenerateCards from './V2Admin/generateCards';
import SyncOrdersV2 from './V2Admin/orders/SyncOrders';
import News from './V2Admin/news';
import './home.css';
import CardsV2 from './V2Admin/cards/Cards';
import CustomersV2 from './V2Admin/customers/Customers';
import CustomerProfile from './V2Admin/customers/CustomerProfile';
import UserProfileCards from './V2Admin/customers/UserProfileCards';
import OrganisationV2 from './V2Admin/organisation';
import SingleOrganisation from './V2Admin/organisation/SingleOrganisation';
import DepartmentsV2 from './V2Admin/organisation/Departments';
import DepartmentMemberV2 from './V2Admin/organisation/DepartmentMember';
import SystemUsersV2 from './V2Admin/systemUsers';
import VendorsV2 from './V2Admin/vendors';
import VendorV2 from './V2Admin/vendors/vendor';
import AssignWorkV2 from './V2Admin/assignWork';
import BatchList from './Vendor/Printer/InProgress/BatchList';
import Batch from './Vendor/Printer/InProgress/Batch';
import * as Sentry from '@sentry/react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ERROR_PAGE_SUBTITLE, ERROR_PAGE_TITLE } from '../common/constant';
import MaintenancePage from './MaintenancePage';
import CardList from './Vendor/Printer/InProgress/CardList';
import Jigs from './Vendor/Printer/InProgress/Jigs';

const { Paragraph } = Typography;

const { Content, Sider } = Layout;
function Home() {
  const { data, currentUser } = React.useContext(AuthContext);
  const { role } = data || [];
  const sortedRoles = [];
  if (role) {
    role.sort();
    role.forEach((role) => {
      if (role === 'DESIGNER') {
        sortedRoles.push(DesignInProgress);
      }
      if (role === 'PRINTER') {
        sortedRoles.push(PrintInProgress);
      }
      if (role === 'SHIPPER') {
        sortedRoles.push(ShipInWarehouse);
      }
    });
  }

  const MyFallbackComponent = ({ error, componentStack }) => (
    <Result
      status="error"
      title={ERROR_PAGE_TITLE}
      subTitle={ERROR_PAGE_SUBTITLE}
    >
      <div className="desc">
        <Paragraph>
          <Typography.Title level={4}> Error:</Typography.Title>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          &nbsp;
          {error?.message?.toString()}
        </Paragraph>
        <Paragraph>
          <Typography.Title level={4}> Stacktrace:</Typography.Title>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
          {componentStack}
        </Paragraph>
      </div>
    </Result>
  );
  // Use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  return (
    <>
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        {maintenance === 'true' ? (
          <MaintenancePage />
        ) : (
          <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
              {currentUser ? (
                <Sider width={300} theme="light">
                  <Link to="/">
                    <Image src="/logo.svg" alt="Mobilo" className="margin-24" />
                  </Link>
                  <div className="sidebar-menu">
                    {data && data.isAdmin ? <HomeMenu /> : <VendorMenu />}
                  </div>
                  <div style={{ position: 'fixed', bottom: 0 }}>
                    <UserMenu data={data} />
                  </div>
                </Sider>
              ) : null}

              <Layout>
                <Content>
                  <Switch>
                    <Route path="/login" component={Login} />
                    <PrivateRoute
                      path="/"
                      exact
                      component={
                        data && !data.isVendor
                          ? OrdersV2
                          : sortedRoles.length
                            ? sortedRoles[0]
                            : Dashboard
                      }
                    />
                    <PrivateRoute
                      path="/v2-orders"
                      exact
                      component={OrdersV2}
                    />

                    <PrivateRoute
                      path="/v2-orders/sync"
                      exact
                      component={SyncOrdersV2}
                    />

                    <PrivateRoute
                      path="/v2-orders/:id"
                      exact
                      component={OrderSetsV2}
                    />
                    <PrivateRoute
                      path="/chat/:id"
                      exact
                      component={InternalChat}
                    />

                    <PrivateRoute
                      path="/v2-orders/:id/:setId"
                      component={OrderCardV2}
                    />

                    <PrivateRoute
                      path="/v2-customers"
                      exact
                      component={CustomersV2}
                    />
                    <PrivateRoute
                      path="/v2-customers/profile/:userId"
                      exact
                      component={CustomerProfile}
                    />

                    <PrivateRoute path="/v2-cards" exact component={CardsV2} />
                    <PrivateRoute
                      path="/v2-customers/:id"
                      component={UserProfileCards}
                    />
                    <PrivateRoute
                      exact
                      path="/v2-vendors"
                      component={VendorsV2}
                    />
                    <PrivateRoute path="/news" component={News} />
                    <PrivateRoute path="/v2-vendors/:id" component={VendorV2} />
                    <PrivateRoute path="/generate" component={GenerateCards} />

                    <PrivateRoute
                      exact
                      path="/v2-organizations"
                      component={OrganisationV2}
                    />
                    <PrivateRoute
                      exact
                      path="/v2-organizations/:id"
                      component={SingleOrganisation}
                    />
                    <PrivateRoute
                      exact
                      path="/v2-organizations/:id/groups"
                      component={DepartmentsV2}
                    />
                    <PrivateRoute
                      exact
                      path="/v2-organizations/:id/groups/:deptId"
                      component={DepartmentMemberV2}
                    />

                    <PrivateRoute
                      path="/v2-systemusers"
                      component={SystemUsersV2}
                    />
                    <PrivateRoute
                      path="/v2-assignwork"
                      component={AssignWorkV2}
                    />
                    <PrivateRoute
                      path="/design/inprogress"
                      exact
                      component={DesignInProgress}
                    />
                    <PrivateRoute
                      path="/design/sets/:id"
                      exact
                      component={DesignInProgressSet}
                    />
                    <PrivateRoute
                      path="/design/sets/addDesign/:id"
                      component={AddDesign}
                    />
                    <PrivateRoute
                      path="/design/waitingforapproval"
                      component={DesignWaitingForApproval}
                    />
                    <PrivateRoute
                      path="/design/completed"
                      component={DesignCompleted}
                    />
                    <PrivateRoute
                      path="/print/inprogress"
                      exact
                      component={PrintInProgress}
                    />
                    <PrivateRoute
                      path="/print/inprogress/:id"
                      component={AssignCard}
                    />
                    <PrivateRoute path="/print/cards/:id" component={cards} />
                    <PrivateRoute
                      path="/print/completed"
                      component={PrintCompleted}
                    />
                    <PrivateRoute
                      path="/print/batch"
                      component={BatchList}
                      exact
                    />
                    <PrivateRoute path="/cards" component={CardList} exact />
                    <PrivateRoute path="/jigs" component={Jigs} exact />
                    <PrivateRoute path="/print/batch/:id" component={Batch} />
                    <PrivateRoute
                      path="/ship/intransit"
                      component={ShipInTransit}
                    />
                    <PrivateRoute path="/ship/pickup" component={ShipPickup} />
                    <PrivateRoute
                      path="/ship/inwarehouse"
                      component={ShipInWarehouse}
                    />
                    <PrivateRoute
                      path="/ship/completed"
                      component={ShipCompleted}
                    />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </BrowserRouter>
        )}
      </Sentry.ErrorBoundary>
    </>
  );
}

export default Home;
