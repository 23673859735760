import axios from 'axios';
import { message } from 'antd';
import { firebase } from '../firebase';
import * as Sentry from '@sentry/browser';
import { FIREBASE_ERRORS_TO_BLOCK } from './constant';

const api = axios?.create({
  baseURL: process?.env?.REACT_APP_SERVER_URL
});

const toast = ({ message: content, type }) => {
  message.destroy();
  switch (type) {
    case 'info':
      message.info(content);
      break;
    case 'success':
      message.success(content);
      break;
    case 'warning':
      message.warning(content);
      break;
    case 'error':
      message.error(content);
      break;
    default:
      break;
  }
};

const acquireFirebaseToken = () => {
  return new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        unsubscribe();
        user.getIdToken().then((token) => {
          resolve(token);
        });
      }
    });
  });
};

api.interceptors.request.use(
  async (config) => {
    try {
      const firebaseToken = await acquireFirebaseToken();
      config.headers.Authorization = `Bearer ${firebaseToken}`;
      return config;
    } catch (error) {
      return error;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response?.config?.method !== 'get')
      toast({
        message: response?.data?.message || 'Operation successful',
        type: response?.status === 'ERROR' ? 'error' : 'success'
      });
    return response;
  },
  (error) => {
    toast({
      message: error?.response?.data?.message || 'Something went wrong!',
      type: 'error'
    });
    if (!FIREBASE_ERRORS_TO_BLOCK?.includes(error?.message)) {
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${error?.response?.data?.message}`
        )
      );
    }
    if (error?.response?.data?.statusCode === 401) {
      firebase
        .auth()
        .signOut()
        .then(localStorage?.clear(), (window.location.href = '/login'))
        .catch((firebaseError) => {
          return firebaseError;
        });
    }
  }
);
export default api;
