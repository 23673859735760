import React, { useState, useEffect, useContext, useRef } from 'react';
import { firebase } from '../../../../firebase';
import {
  Table,
  Layout,
  Typography,
  Card,
  Tag,
  Spin,
  Space,
  Tooltip,
  Badge,
  Button,
  Row,
  Col
} from 'antd';
import { AuthContext } from '../../../../Auth';
import { withRouter } from 'react-router';
import { BatchPopModal, PopModal } from '../Popconfirm';
import { getNewMessageIndicator } from '../../../../utils/getNewMessageIndicator';
import { Link } from 'react-router-dom';
import {
  MessageOutlined,
  MessageTwoTone,
  FlagOutlined,
  FlagFilled
} from '@ant-design/icons';
import { generateDate } from '../../../../utils/generateDate';
import {
  catchErrorInSentry,
  formOrderNumber,
  scrollToTop
} from '../../../../common/utils';
import { UpCircleOutlined } from '@ant-design/icons';
import { LIMIT_PRIMARY } from '../../../../common/constant';
import _isEmpty from 'lodash/isEmpty';
import { getColumnSearchPropsV2 } from '../../../../utils/searchV2';
import { handleTableChange } from '../functions/handleTableChange';
import { handleOrderLengthAndScrollOffset } from '../../../../utils/handleOrderLengthAndScrollOffset';
import { AppContext } from '../../../../AppContext';
import api from '../../../../common/api';

function ShipInTransit() {
  const { data } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [variation, setVariation] = useState([]);
  const [sorterInfo, setSorterInfo] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [sorting, setSorting] = useState('desc');
  const [batchLoading, setBatchLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const searchInput = useRef(null);
  const {
    dispatch,
    state: { totalLoadedOrders, scrollOffset, searchTerm, searchedField }
  } = useContext(AppContext);

  let ref;
  if (data?.vendorId) {
    ref = firebase
      .firestore()
      .collection('orders')
      .where('state', '==', 'SHIPPING')
      .where('status', '==', 'IN_TRANSIT')
      .where('shippingVendorId', '==', data?.vendorId);
    if (searchedColumn && searchText) {
      if (searchedColumn === 'orderUniqueId') {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toUpperCase())
          .endAt(searchText?.toUpperCase() + '\uf8ff');
      } else if (searchedColumn === 'orderNumber') {
        // orderNumber is displayed as Mxxxx on portal and stored as xxxx in database so here search flexibility is given for both the type of searched text
        if (searchText?.split('M')[1]?.length > 0) {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText.split('M')[1]?.toUpperCase())
            .endAt(searchText.split('M')[1]?.toUpperCase() + '\uf8ff');
        } else {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        }
      } else if (sorting && sortColumn === 'updatedAt') {
        ref = ref
          .orderBy(searchedColumn)
          .orderBy(sortColumn, sorting)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      } else {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      }
    }
    if (variation?.length > 0) {
      ref = ref.where('cardVariation', 'in', variation);
    }
    if (!searchText) {
      ref = ref.orderBy(sortColumn, sorting);
    }
    ref = ref.limit(totalLoadedOrders || LIMIT_PRIMARY);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      render: function OrderUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [active, setActive] = useState(false);
        const [spin, setSPin] = useState(true);
        getNewMessageIndicator(
          record?.docId,
          data?.userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        if (spin) return <Spin />;

        return (
          <Space>
            <Typography.Text>{text}</Typography.Text>
            <Link
              to={`/chat/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.scrollY,
                  searchText,
                  searchedColumn
                );
              }}
            >
              <Tooltip title="Internal Chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone
                      twoToneColor="#52c41a"
                      style={{ color: 'green', margin: 2 }}
                    />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore,
        'orders'
      )
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail'
    },
    {
      title: 'Customer Name(on address)',
      key: 'customerName',
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.shippingAddress?.first_name}{' '}
            {record?.shippingAddress?.last_name}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Shipping Address',
      key: 'shippingAddress',
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.shippingAddress?.address_1},{' '}
            {record?.shippingAddress?.address_2},{' '}
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state},{' '}
            {record?.shippingAddress?.country}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Postal Code',
      key: 'postalCode',
      dataIndex: ['shippingAddress', 'postcode']
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: ['shippingAddress', 'company']
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order,
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      onFilter: (text, record) => {
        sessionStorage.setItem('addOnsFilter', JSON.stringify([text]));
        return record?.cardVariation === text;
      },
      defaultFilteredValue:
        JSON.parse(sessionStorage.getItem('addOnsFilter')) || [],
      render: (text, record) => {
        const tags = [];
        try {
          const string = text?.toLowerCase();
          if (!string?.includes('card only')) {
            if (string?.includes('key fob'))
              tags.push({ text: '+ Key Fob', color: 'orange' });
            if (string?.includes('smart button'))
              tags.push({ text: '+ Smart Button', color: 'green' });
          }
        } catch {}
        return (
          <>
            {tags.map((tag) => {
              return (
                <Tag key={`${record?.id}-${tag?.text}`} color={tag?.color}>
                  {tag?.text}
                </Tag>
              );
            })}
          </>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: function Action(text, record) {
        return (
          <Space size={0}>
            <PopModal
              record={record}
              firebase={firebase}
              status="COMPLETED"
              state="COMPLETED"
              loggerState="Transit completed"
              getOrders={getOrders}
            />
          </Space>
        );
      }
    }
  ];

  function getOrders() {
    setLoading(true);
    ref.get().then((querySnapshot) => {
      const items = [];
      querySnapshot.docs.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        orderNumber = formOrderNumber(
          data?.isSendCloudUser,
          orderNumber,
          doc?.data()
        );
        items.push({
          ...doc?.data(),
          key: doc?.data()?.orderUniqueId,
          docId: doc?.id,
          customerEmail: doc?.data()?.user,
          orderNumber
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
      window.scrollTo(0, scrollOffset);
      dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
      dispatch({ type: 'SET_SCROLL_OFFSET', data: 0 });
      dispatch({ type: 'SET_SEARCH_TEXT', data: '' });
      dispatch({ type: 'SET_SEARCHED_COLUMN', data: '' });
    });
  }

  const getNextOrder = async () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          orderNumber = formOrderNumber(
            data?.isSendCloudUser,
            orderNumber,
            doc?.data()
          );
          items.push({
            ...doc?.data(),
            key: doc?.data()?.orderUniqueId,
            docId: doc?.id,
            customerEmail: doc?.data()?.user,
            orderNumber
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
      });
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  const getCountOfOrderCards = async () => {
    const response = await api.get(
      `${process.env.REACT_APP_SERVER_URL_V2}/mat/order-count?status=IN_TRANSIT&vendor=shippingVendorId&state=SHIPPING`
    );
    if (response?.data) {
      setTotalOrders(response?.data?.data?.totalOrdersCount);
      setTotalCards(response?.data?.data?.totalCardsCount);
    }
  };

  useEffect(() => {
    if (data?.vendorId) {
      getOrders();
    }
    getCountOfOrderCards();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window?.scrollY > 300);
    });
  }, [data, searchText, variation, sorting]);

  useEffect(() => {
    if (data?.vendorId) {
      if (searchTerm?.length > 0 && searchedField) {
        setSearchText(searchTerm);
        setSearchedColumn(searchedField);
      }
    }
  }, [data]);

  return (
    <Card
      title={
        <>
          <span className="mr-16">In transit</span>
          <Space direction="vertical">
            <span className="font-400 font-14">
              Order count : {totalOrders ?? '-'}
            </span>
            <span className="font-400 font-14">
              Card count : {totalCards ?? '-'}
            </span>
          </Space>
        </>
      }
      size="small"
      extra={
        <BatchPopModal
          record={selectedRows}
          status="COMPLETED"
          state="COMPLETED"
          loggerState="Transit completed"
          firebase={firebase}
          setLoader={setBatchLoading}
          loader={batchLoading}
          getOrders={getOrders}
        />
      }
    >
      <Table
        rowSelection={rowSelection}
        dataSource={orders}
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          handleTableChange(pagination, filters, sorter, {
            setVariation,
            setSorterInfo,
            setSorting,
            setSortColumn
          });
        }}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextOrder();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(ShipInTransit);
