import { Button, Card, Table, Tabs, Typography, Tooltip } from 'antd';
import React, { useEffect, useContext } from 'react';
import api from '../../../../common/api';
import { LeftOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../Auth';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { generateDate } from '../../../../utils/generateDate';
import { DeleteOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';

function BatchList() {
  const { data } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('IN_PROGRESS');
  const [batchList, setBatchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const handleBatchDelete = async (record) => {
    const config = {
      method: 'DELETE',
      url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/remove-batch?batchId=${record?.id}`,
      headers: {
        secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
      }
    };
    await api(config);
    getBatchData();
  };
  const columns = [
    {
      title: 'Batch',
      dataIndex: 'createdAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'Action',
      width: '10%',
      render: (text, record) => {
        function showConfirm() {
          confirm({
            title: 'Are you sure you want to remove this batch?',
            onOk() {
              handleBatchDelete(record);
            },
            okText: 'Confirm',
            onCancel() {}
          });
        }
        return (
          <div>
            <Tooltip title="Remove Batch">
              <Button
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  showConfirm();
                }}
                className="red-btn"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const getBatchData = async () => {
    try {
      setLoading(true);
      const result = await api.get(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/get-batch-by-user-id?status=${activeTab}`,
        { headers: { secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET } }
      );
      setLoading(false);
      setBatchList(result?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (data?.userId) {
      getBatchData();
    }
  }, [data?.userId, activeTab]);

  return (
    <div>
      <Card
        title={
          <Typography.Text>
            <LeftOutlined
              onClick={() => {
                history.goBack();
              }}
              className="mr-16"
            />
            Batch List
          </Typography.Text>
        }
      >
        <Tabs
          defaultActiveKey="1"
          onTabClick={(e) => {
            setActiveTab(e);
          }}
        >
          <Tabs.TabPane tab="In Progress" key="IN_PROGRESS">
            <Table
              columns={columns}
              dataSource={batchList}
              loading={loading}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/print/batch/${record?.id}`);
                  }
                };
              }}
              className="cursor-pointer"
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="COMPLETED">
            <Table
              columns={columns}
              dataSource={batchList}
              loading={loading}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/print/batch/${record?.id}`);
                  }
                };
              }}
              className="cursor-pointer"
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default BatchList;
