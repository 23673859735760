import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { firebase } from '../../../firebase';
import { EditOutlined } from '@ant-design/icons';
import { catchErrorInSentry } from '../../../common/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function EditVendor({ record }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [showAddressField, setShowAddressField] = useState(false);

  const systemUserRef = firebase.firestore().collection('systemUser');

  const handleSubmit = async (event) => {
    const { email, name, vendorType, phoneNumber, address } = event;
    setLoading(true);
    try {
      await firebase
        .firestore()
        .collection('vendor')
        .doc(record?.id)
        .update({
          email: email?.toLowerCase()?.trim(),
          name,
          vendorType,
          phoneNumber,
          ...(address ? { fromAddressId: address } : {})
        });

      systemUserRef
        .where('vendorId', '==', record?.id)
        .where('isDefault', '==', true)
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            systemUserRef.doc(doc?.data()?.userId).update({
              email: email?.toLowerCase()?.trim(),
              fullName: name,
              role: vendorType,
              phoneNumber,
              ...(address ? { fromAddressId: address } : {})
            });
          });
        })
        .catch((error) => {
          catchErrorInSentry(error);
        });
      setVisible(false);
      setLoading(false);
    } catch (error) {
      catchErrorInSentry(error);
      alert(error);
    }
  };
  const getVendorAddresses = async () => {
    const items = [];
    const vendorAddresses = await firebase
      .firestore()
      .collection('vendor_addresses')
      .get();
    vendorAddresses.docs.forEach((doc) => {
      items.push({
        ...doc?.data(),
        id: doc?.id,
        address: `${doc?.data()?.name} ${doc?.data()?.street1}, ${
          doc?.data()?.city
        }, ${doc?.data()?.state}, ${doc?.data()?.zip}, ${doc?.data()?.country}`
      });
    });
    setAddresses(items);
  };

  useEffect(() => {
    getVendorAddresses();
  }, []);
  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="text" icon={<EditOutlined />} onClick={() => showModal()} />

      <Modal
        title={record?.name}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={record}
        >
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: 'Please enter Phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter email!' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Service"
            name="vendorType"
            rules={[{ required: true, message: 'Please enter services!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select Services"
              style={{ width: '100%' }}
              onChange={(e) => {
                setShowAddressField(e?.includes('SHIPPER'));
              }}
            >
              <Select.Option key="PRINTER">Print</Select.Option>
              <Select.Option key="SHIPPER">Ship</Select.Option>
              <Select.Option key="DESIGNER">Design</Select.Option>
            </Select>
          </Form.Item>
          {showAddressField && (
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please select address' }]}
            >
              <Select
                placeholder="Select FROM address of this vendor"
                defaultValue={record?.fromAddressId}
              >
                {addresses?.map((address) => {
                  return (
                    <Select.Option key={address?.id}>
                      {address?.address}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              className="margin-l"
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditVendor;
