import React, { useState, useEffect, useContext, useRef } from 'react';
import { firebase } from '../../../../firebase';
import {
  Table,
  Typography,
  Button,
  message,
  Modal,
  Tag,
  Tooltip,
  Badge,
  Space,
  Spin,
  Image,
  Descriptions,
  Row,
  Col,
  Card
} from 'antd';
import { AuthContext } from '../../../../Auth';
import { useHistory, withRouter } from 'react-router';
import {
  CheckOutlined,
  MessageOutlined,
  MessageTwoTone,
  FlagFilled,
  FlagOutlined,
  UpCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getNewMessageIndicator } from '../../../../utils/getNewMessageIndicator';
import api from '../../../../common/api';
import {
  CARDTYPE_FOR_THUMBNAIL,
  ERROR_MESSAGES,
  LIMIT_PRIMARY,
  MAT,
  METAL_VENDOR
} from '../../../../common/constant';
import { generateDate } from '../../../../utils/generateDate';
import { getColumnSearchPropsV2 } from '../../../../utils/searchV2';
import _isEmpty from 'lodash/isEmpty';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { getCardStatus, scrollToTop } from '../../../../common/utils';
import { AppContext } from '../../../../AppContext';
import { handleOrderLengthAndScrollOffset } from '../../../../utils/handleOrderLengthAndScrollOffset';
import Loader from '../../../Loader/Loader';

const { confirm } = Modal;

function PrintInProgress() {
  const { data } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [totalSelectedCards, setTotalSelectedCards] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSummeryModalOpen, setIsSummeryModalOpen] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [offset, setOffset] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sorting, setSorting] = useState('desc');
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [variation, setVariation] = useState([]);
  const [sorterInfo, setSorterInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [singleCardOrder, setSingleCardOrder] = useState();
  const [imagePreview, setImagePreview] = useState([]);
  const [imagePreviewFetching, setImagePreviewFetching] = useState(false);
  const [isLoadAll, setIsLoadAll] = useState(false);
  const [current, setCurrent] = useState(1);
  const searchInput = useRef(null);
  const history = useHistory();
  const {
    dispatch,
    state: { totalLoadedOrders, scrollOffset, searchTerm, searchedField }
  } = useContext(AppContext);
  let ref;
  if (data?.vendorId) {
    ref = firebase
      .firestore()
      .collection('orders')
      .where('status', '==', 'PRINTING_IN_PROCESS')
      .where('state', '==', 'PRINTING')
      .where('printingVendorId', '==', data?.vendorId);
    if (searchedColumn && searchText) {
      if (searchedColumn === 'orderUniqueId') {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toUpperCase())
          .endAt(searchText?.toUpperCase() + '\uf8ff');
      } else if (searchedColumn === 'orderNumber') {
        if (searchText?.split('M')[1]?.length > 0) {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText.split('M')[1]?.toUpperCase())
            .endAt(searchText.split('M')[1]?.toUpperCase() + '\uf8ff');
        } else {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        }
      } else if (sorting && sortColumn === 'updatedAt') {
        ref = ref
          .orderBy(searchedColumn)
          .orderBy(sortColumn, sorting)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      } else {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      }
    }
    if (variation?.length > 0) {
      ref = ref.where('cardVariation', 'in', variation);
    }
    if (!searchText) {
      ref = ref.orderBy(sortColumn, sorting);
    }
    ref = ref.limit(totalLoadedOrders || LIMIT_PRIMARY);
  }

  useEffect(() => {
    window.addEventListener('beforeunload', () => sessionStorage.clear());
    return () => {
      window.removeEventListener('beforeunload', () => sessionStorage.clear());
    };
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore
      ),
      render: function OrderUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [active, setActive] = useState(false);
        const [spin, setSPin] = useState(true);
        getNewMessageIndicator(
          record?.docId || record?.id,
          data?.userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        if (spin) return <Spin />;

        return (
          <Space>
            <Link
              to={`/print/cards/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.scrollY,
                  searchText,
                  searchedColumn
                );
              }}
            >
              {text}
            </Link>
            <Link
              to={`/chat/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.scrollY,
                  searchText,
                  searchedColumn
                );
              }}
            >
              <Tooltip title="Internal Chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone
                      twoToneColor="#52c41a"
                      style={{ color: 'green', margin: 2 }}
                    />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: (a, b) => parseInt(a?.orderNumber) - parseInt(b?.orderNumber),
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        setHasMore,
        'orders'
      )
    },
    {
      title: () => (
        <div>
          Cards {totalSelectedCards}/{selectedOrders?.length}
        </div>
      ),
      dataIndex: 'totalQuantity',
      key: 'cards',
      filters: [
        {
          text: 'Single',
          value: true
        },
        {
          text: 'Mutiple',
          value: false
        }
      ],
      filterMultiple: false
    },
    {
      title: 'Customer Email',
      dataIndex: 'user',
      key: 'customerEmail'
    },
    {
      title: 'Card thumbnail',
      dataIndex: 'thumbNail',
      key: 'thumbNail',
      render: function Thumbnail(text, record) {
        const [thumbNail, setThumbNail] = useState();
        const [isModalOpen, setIsModalOpen] = useState(false);

        const showModal = () => {
          setIsModalOpen(true);
        };

        const handleOk = () => {
          setIsModalOpen(false);
        };

        const handleCancel = () => {
          setIsModalOpen(false);
        };

        const getImage = function () {
          const promise = new Promise(function (resolve, reject) {
            try {
              if (CARDTYPE_FOR_THUMBNAIL?.includes(record?.cardType)) {
                const result = api.get(
                  `${process?.env?.REACT_APP_SERVER_URL_V2}/mat/get-card-preview?orderUniqueId=${record?.orderUniqueId}`
                );
                resolve(result);
              }
            } catch (error) {
              reject(error);
            }
          });

          return promise;
        };
        useEffect(() => {
          if (!thumbNail) {
            getImage().then(function (result) {
              setThumbNail(result?.data?.data);
            });
          }
        }, [record]);

        if (CARDTYPE_FOR_THUMBNAIL?.includes(record?.cardType)) {
          return (
            <>
              <Image
                src={thumbNail}
                width={49}
                preview={false}
                onClick={showModal}
              />
              <Modal
                title={record?.orderUniqueId}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1024}
                footer={false}
                className="modal-position"
              >
                <Image src={thumbNail} preview={false} />
              </Modal>
            </>
          );
        }
      }
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order,
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'File Status',
      dataIndex: 'cardGenerationStatus',
      key: 'cardGenerationStatus',
      render: function GetStatus(text, record) {
        const [cardGenerationStatus, setCardGenerationStatus] = useState('');
        const [aiConversionStatus, setAiConversionStatus] = useState('');
        const [cardGenerationCount, setCardGenerationCount] = useState(0);
        const [failedReason, setFailedReason] = useState();
        const getLiveStatus = async () => {
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.docId)
            .onSnapshot((doc) => {
              setCardGenerationStatus(doc?.data()?.cardGenerationStatus);
              setAiConversionStatus(doc?.data()?.aiConversionStatus);
              setCardGenerationCount(doc?.data()?.cardGenerationCount);
              setFailedReason(doc?.data()?.failedReason);
            });
        };
        useEffect(() => {
          getLiveStatus();
        }, []);
        useEffect(() => {
          getCardStatus(
            record?.cardType,
            cardGenerationStatus,
            aiConversionStatus,
            cardGenerationCount,
            record?.totalQuantity
          );
        }, [
          cardGenerationStatus,
          aiConversionStatus,
          cardGenerationCount,
          failedReason
        ]);
        return (
          <div
            title={ERROR_MESSAGES[`${failedReason}`]}
            className={`${failedReason && 'red-btn'} cursor-pointer`}
          >
            {getCardStatus(
              record?.cardType,
              cardGenerationStatus,
              aiConversionStatus,
              cardGenerationCount,
              record?.totalQuantity
            )}
          </div>
        );
      }
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      onFilter: (text, record) => {
        sessionStorage.setItem('addOnsFilter', JSON.stringify([text]));
        return record?.cardVariation === text;
      },
      filterMultiple: false,
      defaultFilteredValue:
        JSON.parse(sessionStorage.getItem('addOnsFilter')) || [],
      render: (text, record) => {
        const tags = [];
        try {
          const string = text?.toLowerCase();
          if (!string?.includes('card only')) {
            if (string?.includes('key fob'))
              tags.push({ text: '+ Key Fob', color: 'orange' });
            if (string?.includes('smart button'))
              tags.push({ text: '+ Smart Button', color: 'green' });
          }
        } catch {}
        return (
          <>
            {tags.map((tag) => {
              return (
                <Tag key={tag} color={tag?.color}>
                  {tag?.text}
                </Tag>
              );
            })}
          </>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: function Action(text, record) {
        const [flag, setFlag] = useState(record?.bug || false);
        const [failedReason, setFailedReason] = useState();
        const [cardGenerationStatus, setCardGenerationStatus] = useState();
        const [aiConversionStatus, setAiConversionStatus] = useState();
        const [status, setStatus] = useState();
        const batchStartIndexes = [];
        const batchSize = 50;
        const getLiveStatus = async () => {
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.docId)
            .onSnapshot((doc) => {
              setCardGenerationStatus(doc?.data()?.cardGenerationStatus);
              setAiConversionStatus(doc?.data()?.aiConversionStatus);
              setFailedReason(doc?.data()?.failedReason);
              const getUpdatedStatus = getCardStatus(
                doc?.data()?.cardType,
                doc?.data()?.cardGenerationStatus,
                doc?.data()?.aiConversionStatus,
                doc?.data()?.cardGenerationCount,
                doc?.data()?.totalQuantity
              );
              setStatus(getUpdatedStatus);
            });
        };
        useEffect(() => {
          getLiveStatus();
        }, []);
        useEffect(() => {
          getCardStatus(
            record?.cardType,
            record?.cardGenerationStatus,
            record?.aiConversionStatus,
            record?.cardGenerationCount,
            record?.totalQuantity
          );
        }, [cardGenerationStatus, aiConversionStatus, failedReason]);
        const handleRetry = async () => {
          await api.post(
            `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/create`,
            {
              orderUniqueId: record?.orderUniqueId,
              retry: true
            },
            {
              headers: {
                secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
              }
            }
          );
        };
        function showConfirm() {
          confirm({
            title: `Are you sure you want to change status of ${record?.orderUniqueId} to 'PRINTING_COMPLETED'`,
            async onOk() {
              const response = await api?.get(
                `${process.env.REACT_APP_API_GTW_URL}/v1/best-factory/orders/${record?.orderUniqueId}`
              );
              await firebase
                .firestore()
                .collection('orders')
                .doc(response?.data?.data?.id)
                .update({
                  status: 'PRINTING_COMPLETED'
                });
              // fromPortal is added to identify from which platform the API is called
              api.post(
                `${process?.env?.REACT_APP_SERVER_URL_V2}/orders/assign-work`,
                {
                  orderUniqueId: record?.orderUniqueId,
                  vendorType: 'SHIPPER',
                  fromPortal: MAT
                }
              );
              message.success('Order status updated successfully');
            },
            onCancel() {}
          });
        }
        async function handleFlagClick() {
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.docId)
            .update({
              bug: !flag
            })
            .then(() => {
              if (searchText) {
                getOrders();
              } else {
                getFilteredData(singleCardOrder, false, true);
              }
              setFlag(!flag);
            });
        }

        if (record?.quantity > batchSize) {
          for (let i = 0; i < record?.quantity; i++) {
            if (i % batchSize == 0) {
              if (record?.quantity - i >= batchSize)
                batchStartIndexes.push({
                  startIndex: i,
                  count: batchSize
                });
              else
                batchStartIndexes.push({
                  startIndex: i,
                  count: record?.quantity - i
                });
            }
          }
        }

        return (
          <Space size={16}>
            {status === 'Failed' ? (
              <Button
                disabled={record?.isRetryBtnDisable || false}
                onClick={() => {
                  handleRetry();
                }}
              >
                Retry
              </Button>
            ) : status === 'Completed' &&
              cardGenerationStatus !== 'PROCESSING' &&
              aiConversionStatus !== 'PROCESSING' &&
              record?.cardType !== 'Mobilo Card' ? (
              !batchStartIndexes?.length ? (
                <Button
                  disabled={record?.isDownloadBtnDisable}
                  target="_blank"
                  href={`${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/zip?orderUniqueId=${record?.orderUniqueId}&startIndex=0&count=${record?.quantity}`}
                >
                  Download
                </Button>
              ) : (
                <>
                  {batchStartIndexes.map((value, index) => {
                    return (
                      <Button
                        key={index}
                        target="_blank"
                        href={`${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/zip?orderUniqueId=${record?.orderUniqueId}&startIndex=${value?.startIndex}&count=${value?.count}`}
                      >
                        B{index + 1}
                      </Button>
                    );
                  })}
                </>
              )
            ) : null}

            {(record?.cardType === 'Mobilo Card' ||
              record?.cardType === 'Mobilo Metal (promo)' ||
              record?.cardType === 'Mobilo Metal' ||
              record?.cardType === 'Free Mobilo Card') &&
            !record?.allCardsAssigned ? (
              <Link to={`/print/inprogress/${record?.orderUniqueId}`}>
                <Button>Assign Codes</Button>
              </Link>
            ) : (
              <>
                <Button
                  type="text"
                  icon={<CheckOutlined />}
                  onClick={showConfirm}
                  style={{ color: 'green' }}
                />
                <Tooltip title={'Report bug'}>
                  {!(record?.bug || false) ? (
                    <Button
                      type="text"
                      icon={<FlagOutlined />}
                      onClick={handleFlagClick}
                    />
                  ) : (
                    <Button
                      type="text"
                      icon={<FlagFilled style={{ color: '#d33e48' }} />}
                      onClick={handleFlagClick}
                    />
                  )}
                </Tooltip>
              </>
            )}
          </Space>
        );
      }
    }
  ];

  function getOrders() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.docs.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        orderNumber = `M${orderNumber}`;
        items.push({
          ...doc?.data(),
          key: doc?.data()?.orderUniqueId,
          docId: doc?.id,
          customerEmail: doc?.data()?.user,
          orderNumber
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
      window.scrollTo(0, scrollOffset);
      dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
      dispatch({ type: 'SET_SCROLL_OFFSET', data: 0 });
      dispatch({ type: 'SET_SEARCH_TEXT', data: '' });
      dispatch({ type: 'SET_SEARCHED_COLUMN', data: '' });
    });
  }

  const getNextOrder = async () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          orderNumber = `M${orderNumber}`;
          items.push({
            ...doc?.data(),
            key: doc?.data()?.orderUniqueId,
            docId: doc?.id,
            customerEmail: doc?.data()?.user,
            orderNumber
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getCountOfOrderCards = async () => {
    const response = await api.get(
      `${process?.env?.REACT_APP_SERVER_URL_V2}/mat/order-count?status=PRINTING_IN_PROCESS&vendor=printingVendorId&state=PRINTING`
    );
    if (response?.data) {
      setTotalOrders(response?.data?.data?.totalOrdersCount);
      setTotalCards(response?.data?.data?.totalCardsCount);
    }
  };

  const getFilteredData = async (
    singleCardOrder,
    isLoadMore,
    isCalledForRefetch
  ) => {
    setLoading(true);
    const response = await api.get(
      `${
        process?.env?.REACT_APP_PDF_DOWNLOAD_URL
      }/mat/printer/get-single-multiple-orders?${
        typeof singleCardOrder === 'boolean'
          ? `singleCardOrder=${singleCardOrder}`
          : ''
      }${offset && isLoadMore ? `&startAfter=${offset}` : ''}${
        variation?.length > 0
          ? `&cardVariation=${encodeURIComponent(variation)}`
          : ''
      }${
        !isLoadAll
          ? `&limit=${isCalledForRefetch ? orders?.length : LIMIT_PRIMARY}`
          : ''
      }`,
      {
        headers: {
          secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
        }
      }
    );
    if (response?.data) {
      setLoading(false);
      const items = [];
      response.data.data.forEach((doc) => {
        let orderNumber = doc?.orderNumber;
        orderNumber = `M${doc?.orderNumber}`;
        items.push({
          ...doc,
          key: doc?.orderUniqueId,
          docId: doc?.id,
          customerEmail: doc?.user,
          orderNumber
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      if (isLoadMore) {
        setOrders([...orders, ...items]);
      } else {
        setOrders(items);
      }
      setOffset(response?.data?.data?.slice(-1)[0]?.id);
    }
  };

  useEffect(() => {
    if (data?.vendorId) {
      if (searchText) {
        getOrders();
      } else {
        getFilteredData(singleCardOrder, false);
      }
    }
    getCountOfOrderCards();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window?.scrollY > 300);
    });
  }, [data, searchText, variation, sorting, singleCardOrder, isLoadAll]);

  useEffect(() => {
    if (data?.vendorId) {
      if (searchTerm?.length > 0 && searchedField) {
        setSearchText(searchTerm);
        setSearchedColumn(searchedField);
      }
    }
  }, [data]);

  const handleTableChange = (pagination, filter, sorter) => {
    if (filter?.cards?.length > 0) {
      setSingleCardOrder(filter?.cards[0] == 'true');
    } else {
      setSingleCardOrder();
    }
    setVariation(filter?.variation);
    if (!_isEmpty(sorter)) {
      setSorterInfo(sorter);
      const { order, columnKey } = sorter;
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('asc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('asc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('desc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('desc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;
        default:
          setSorting('desc');
          setSortColumn('createdAt');
      }
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedOrders,
    onChange: (selectedRowKeys) => {
      setSelectedOrders(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.cardType !== 'Mobilo Card (custom)'
    })
  };

  const showModal = () => {
    setIsSummeryModalOpen(true);
  };

  const handleOk = async () => {
    if (current === 3) {
      setIsSummeryModalOpen(false);
    }
    if (current === 2) {
      setCurrent(3);
      const createBatchResposne = await api?.post(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/create-batch-from-order-unique-ids`,
        {
          orderUniqueIds: selectedOrders
        },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      console.log(createBatchResposne);
    }
    if (current === 1) {
      setCurrent(2);
      setImagePreviewFetching(true);
      const cardPreview = await api?.post(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/get-order-preview`,
        {
          orderUniqueIds: selectedOrders
        },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );

      let preview = [];
      for (const [key, value] of Object.entries(cardPreview?.data?.data)) {
        preview.push({ orderId: key, front: value?.front, back: value?.back });
      }
      setImagePreviewFetching(false);
      setImagePreview(preview);
    }
  };

  const handleCancel = () => {
    setLoading(true);
    setSelectedOrders([]);
    setImagePreview([]);
    setCurrent(1);
    setLoading(false);
    setIsSummeryModalOpen(false);
  };

  const getCount = async () => {
    try {
      const response = await api.post(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/get-count`,
        { orderUniqueIds: selectedOrders },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      if (response) {
        setTotalSelectedCards(response?.data?.data?.cardsCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedOrders?.length > 0) {
      getCount();
    }
  }, [selectedOrders]);

  return (
    <Card
      title={
        <div className="display-flex justify-between align-center">
          <Space>
            <Typography.Title className="mr-16" level={3}>
              In Progress
            </Typography.Title>
            <Space direction="vertical">
              <span>Orders : {totalOrders ?? '-'}</span>
              <span>Cards : {totalCards ?? '-'}</span>
            </Space>
          </Space>
          <Space>
            {data?.assignedWork == METAL_VENDOR && (
              <Button
                type="primary"
                onClick={() => {
                  history.push({
                    pathname: '/cards',
                    search: '?material=metal'
                  });
                }}
              >
                Create Jig for Metal Cards
              </Button>
            )}
            {data?.assignedWork == METAL_VENDOR && (
              <Button
                type="primary"
                onClick={() => {
                  history.push({
                    pathname: '/cards',
                    search: '?material=wood'
                  });
                }}
              >
                Create Jig for Wood Cards
              </Button>
            )}
            {data?.assignedWork !== METAL_VENDOR && (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    history.push('/print/batch');
                  }}
                >
                  View Batches
                </Button>
                <Button
                  disabled={selectedOrders?.length === 0}
                  type="primary"
                  onClick={() => {
                    showModal();
                  }}
                >
                  Print Batch
                </Button>
              </Space>
            )}
            <Button
              type="primary"
              onClick={() => {
                setIsLoadAll(true);
              }}
            >
              Load All
            </Button>
          </Space>
        </div>
      }
    >
      <Table
        bordered
        dataSource={orders}
        columns={columns}
        onChange={handleTableChange}
        rowSelection={
          data?.assignedWork !== METAL_VENDOR ? rowSelection : false
        }
        pagination={false}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore && searchText) {
                // This function call is for querying search result (Firebase query is written for search)
                getNextOrder();
              } else {
                //API call for pagination (Search feature is currently not supported in API)
                getFilteredData(singleCardOrder, true);
              }
            }}
            disabled={!hasMore || isLoadAll}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
                dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
                dispatch({
                  type: 'SET_SCROLL_OFFSET',
                  data: 0
                });
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>

      <Modal
        visible={isSummeryModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1298}
      >
        {current === 1 && (
          <div>
            <Typography.Text strong>Batch Summary</Typography.Text>
            <Descriptions title="Current filter">
              <DescriptionsItem label="Accessories">
                {variation?.length > 0 ? variation : '-'}
              </DescriptionsItem>
              <DescriptionsItem label="Order type">
                {typeof singleCardOrder === 'boolean'
                  ? singleCardOrder
                    ? 'SINGLE'
                    : 'MULTIPLE'
                  : '-'}
              </DescriptionsItem>
            </Descriptions>
            <Descriptions title="Order details">
              <DescriptionsItem label="Number of Orders">
                {selectedOrders?.length}
              </DescriptionsItem>
              <DescriptionsItem label="Number of Cards">
                {totalSelectedCards}
              </DescriptionsItem>
            </Descriptions>
          </div>
        )}

        {current === 2 && (
          <div>
            <Typography.Text strong>Batch Preview</Typography.Text>
            {imagePreviewFetching ? (
              <Loader />
            ) : (
              <Row className="mt-16">
                {imagePreview?.map((image) => {
                  return (
                    <Col key={image?.orderId} span={6}>
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <img
                              src={image?.front}
                              alt="card preview"
                              className="card-image"
                            />
                          </div>
                          <div className="flip-card-back">
                            <img
                              src={image?.back}
                              alt="card preview"
                              className="card-image"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          cursor: 'pointer'
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setImagePreview(
                            imagePreview.filter(
                              (item) => item?.orderId !== image?.orderId
                            )
                          );
                          setSelectedOrders(
                            selectedOrders.filter(
                              (item) => item !== image?.orderId
                            )
                          );
                        }}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        )}
        {current === 3 && (
          <div>
            <Typography.Text strong>
              Batch creation process has been started &nbsp;
              <Typography.Link href="/print/batch">Click</Typography.Link> here
              to see the status
            </Typography.Text>
          </div>
        )}
      </Modal>
    </Card>
  );
}

export default withRouter(PrintInProgress);
